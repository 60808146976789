<template>
    <div class="login-page">
        <div class="page-body">
            <div v-show="loading" class="loading-spinner">
                <b-spinner class="m-auto" variant="primary" label="Spinning"></b-spinner>
            </div>
            <parallax class="page-parallax-bg" :style="'background-image: url(' + bgSrc + ');'"></parallax>
            <div class="container pt-1" v-if="errors || info">
                <alert v-for="error in errors" :key="error" class="my-1" type="danger" dismissible
                       @dismissed="onDismissAlert(error)">
                    <div class="float-left mt-1 mr-2">
                        <i class="fa fa-exclamation"></i>
                    </div>
                    <strong>{{$t('messages.error')}}!</strong> {{$t(error)}}
                </alert>
                <alert v-for="item in info" :key="item" class="m-0" type="info" dismissible
                       @dismissed="onDismissInfo(item)">
                    <div class="float-left mt-1 mr-2">
                        <i class="fa fa-exclamation"></i>
                    </div>
                    <span>{{$t(item)}}</span>
                </alert>
            </div>

            <div class="mt-0">
                <div class="container pt-0">
                    <div class="col-md-9 col-lg-6 col-xl-5 mx-auto">
                        <div class="logo-container" :style="'background-image: url(' + logo + ')'"></div>

                        <b-input-group>
                            <b-input-group-prepend>
                                <span class="input-group-text"><i class="fa fa-at"></i></span>
                            </b-input-group-prepend>
                            <b-form-input type="email" v-model="email" size="lg"
                                          :placeholder="$t('register.email')"></b-form-input>
                        </b-input-group>

                        <b-button variant="primary" class="mt-5 mb-3 btn-round btn-lg w-100" v-on:click="onResetBtn">
                            {{$t('needhelp.resetbtn')}}
                        </b-button>
                        <b-row class="mb-5">
                            <b-col>
                                <h6>
                                    <router-link tag="a" :to="{name: 'login'}" class="float-left link footer-link">
                                        {{$t('register.login')}}
                                    </router-link>
                                </h6>
                            </b-col>
                            <b-col>
                                <h6>
                                    <router-link tag="a" :to="{name: 'register'}" class="float-right link footer-link">
                                        {{$t('login.create')}}
                                    </router-link>
                                </h6>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Alert} from '@/components';
    import backend from "../../plugins/backend";
    import store from "../../plugins/store";

    export default {
        name: "NeedHelp",

        data() {
            return {
                bgSrc: '/img/plain_black.jpg',
                logo: '/img/bet-logo-t-blue.png',
                email: '',
                errors: [],
                info: [],
                emailRegex: /[^\.\s@][^@\s]*[^\.\s@]@[^\.\s@]+\.[^\.\s@]+/g,
            }
        },
        components: {
            Alert
        },
        computed: {
            loading() {
                return store.getters.isLoading;
            }
        },
        methods: {
            init() {
              this.info = [];
              this.errors = [];
              this.email = '';
            },
            onDismissAlert(e) {
                this.errors = this.errors.filter(element => element !== e);
            },
            onDismissInfo(e) {
                this.info = this.info.filter(element => element !== e);
            },
            validateEmail(value) {
                if (value === '') this.errors.push('messages.email_empty');
                else {
                    if (value.startsWith('.')) this.errors.push('messages.email_invalid');
                    else if ((value.match(/@/g) || []).length !== 1) this.errors.push('messages.email_invalid');
                    else if ((value.match(this.emailRegex) || []).length !== 1) this.errors.push('messages.email_invalid');
                    else return true
                }
                return false
            },
            onResetBtn() {
                let email = this.email.slice();
                let validEmail = this.validateEmail(email);
                if (validEmail && this.errors.length === 0) {
                    this.onReset(email);
                }
            },
            onReset(email) {
                backend.requestPasswordReset(email).then(response => {
                    if (response.success) this.onPasswordResetRequestSuccess();
                    else this.onPasswordResetRequestError(response.errors);
                }).catch(err => {
                    this.onPasswordResetRequestError(err);
                })
            },
            onPasswordResetRequestSuccess(){
                this.info = ['Pár percen belül kapni fogsz egy e-mailt, a benne található linkkel törölheted a jelszavadat!'];
            },
            onPasswordResetRequestError(err) {
                this.errors = ['unexpected_error'];
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>

</style>