<template>
    <div>
        <b-row class="my-1 outline-0">
            <b-col cols="6">
                <b-form-group :label="$t('form.lastName')" label-class="text-left outline-0">
                    <b-form-input :disabled="!editable" required :state="formState.lastName"
                                  @change="checkFormState('lastName')" :placeholder="$t('form.lastName')"
                                  v-model="formData.lastName"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('form.firstName')" label-class="text-left">
                    <b-form-input :disabled="!editable" required :state="formState.firstName"
                                  :placeholder="$t('form.firstName')" @change="checkFormState('firstName')"
                                  v-model="formData.firstName"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col cols="12" class="my-1">
                <b-form-group :label="$t('form.cityVillage')" label-class="text-left">
                    <b-form-input :disabled="!editable" required :state="formState.city"
                                  :placeholder="$t('form.cityVillage')" @change="checkFormState('city')"
                                  v-model="formData.city"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="12" class="my-1">
                <b-form-group :label="$t('form.addressLine')" label-class="text-left">
                    <b-form-input :disabled="!editable" required :state="formState.addressLine1"
                                  :placeholder="'1. ' + $t('form.addressLine')" @change="checkFormState('addressLine1')"
                                  v-model="formData.addressLine1"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="12" class="my-1">
                <b-form-group>
                    <b-form-input :disabled="!editable" :state="formState.addressLine2"
                                  :placeholder="'2. ' + $t('form.addressLine')" @change="checkFormState('addressLine2')"
                                  v-model="formData.addressLine2"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="12" class="my-1">
                <b-form-group :label="$t('form.postCode')" label-class="text-left">
                    <b-form-input :disabled="!editable" required :state="formState.postCode"
                                  :placeholder="$t('form.postCode')" :number="true" @change="checkFormState('postCode')"
                                  v-model="formData.postCode"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col cols="12" class="my-1">
                <b-form-group :label="$t('form.phone')" label-class="text-left">
                    <b-form-input :disabled="!editable" :state="formState.phone" :placeholder="$t('form.phone')"
                                  @change="checkFormState('phone')" v-model="formData.phone"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="12" class="my-1">
                <b-form-group :label="$t('form.email')" label-class="text-left">
                    <b-form-input :disabled="!editable" required :state="formState.email"
                                  :placeholder="$t('form.email')" @change="checkFormState('email')"
                                  v-model="formData.email"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "InvoiceForm",
        props: {
            form: {
                Type: Object
            },
            formState: {
                Type: Object,
                default: () => {
                    return {};
                }
            },
            editable: {
                Type: Boolean,
                default: true
            }
        },
      computed: {
          formData() {
            return this.form;
          }
      },
        methods: {
            checkFormState(value) {
                this.$emit('change', value);
            }
        }
    }
</script>

<style scoped>
    .form-control[disabled] {
        color: black !important;
        font-size: 1.2em;
    }
</style>