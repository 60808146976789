<template>
  <PDF :src="src"/>
</template>

<script>
import PDF from "@/pages/index/PDF";

export default {
  name: "GDPR",
  components: {
    PDF
  },
  data() {
    return {
      src: 'https://firebasestorage.googleapis.com/v0/b/sportfogadokclubja.appspot.com/o/public%2Fdoc%2Fgdpr.pdf?alt=media&embedded=true'
    }
  }
}
</script>

<style scoped>

</style>