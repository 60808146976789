/*!

 =========================================================
 * Vue Now UI Kit - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import App from './App.vue';
// You can change this import to `import router from './starterRouter'` to quickly start development from a blank layout.
import router from './scRouter';
import NowUiKit from './plugins/now-ui-kit';
import {i18n} from './plugins/i18n';
import BootstrapVue from 'bootstrap-vue';
import 'vue-search-select/dist/VueSearchSelect.css';
import Helper from './plugins/helper';
import {refreshPageMixin} from './plugins/refreshPageMixin';

Vue.config.productionTip = false;

Vue.use(NowUiKit);
Vue.use(i18n);
Vue.use(BootstrapVue);

Vue.mixin(Helper);
Vue.mixin(refreshPageMixin);

new Vue({
  router,
  i18n,
  render: h => h(App),
  data() {
    return {
      windowWidth: 0,
      windowHeight: 0,
      isMobile: false,
      version: 'PROD - 1.2.5'
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.isMobile = this.windowWidth < 768;
    }
  }
}).$mount('#app');
