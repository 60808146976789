<template>
    <div class="page-checkout">
        <div v-show="!loaded || loading" class="loading-spinner">
            <b-spinner class="m-auto" variant="primary" label="Spinning"></b-spinner>
        </div>
        <parallax class="page-parallax-bg"></parallax>
        <b-row class="main-container clear-filter" filter-color="checkout-page-shadow">
            <b-col class="secondary-container">
                <div class="container pt-1" v-if="errors">
                    <alert v-for="error in errors" :key="error" class="my-1" type="danger" dismissible
                           @dismissed="onDismissAlert(error)">
                        <div class="float-left mt-1 mr-2">
                            <i class="fa fa-exclamation"></i>
                        </div>
                        <strong>{{$t('messages.error')}}!</strong> {{$t(error)}}
                    </alert>
                </div>
                <b-row class="checkout-container">
                    <b-col class="checkout-secondary-container">
                        <b-row class="checkout-title mb-4">
                            <b-col>
                                <div v-if="!paymentSuccess">Fizetés</div>
                                <div v-else>Sikeres rendelés!</div>
                            </b-col>
                        </b-row>
                        <b-row v-show="!paymentSuccess" class="checkout-products" align-h="center">
                            <b-col cols="12" xl="10" class="products-container">
                                <div v-if="isMobile">
                                    <b-row v-for="p in [product]" :key="p.id">
                                        <hr style="width: 90%;">
                                        <b-col>
                                            <b-row align-h="center">
                                                <b-col cols="4" align-self="center">
                                                    <div class="mx-auto font-weight-bold" style="width: fit-content;">
                                                        Cikk
                                                    </div>
                                                </b-col>
                                                <b-col cols="8"><img class="table-item-image" :src="p.img"/>
                                                </b-col>
                                            </b-row>
                                            <hr style="width: 80%">
                                            <b-row class="my-3" align-h="center">
                                                <b-col cols="4" align-self="center">
                                                    <div class="mx-auto font-weight-bold" style="width: fit-content;">
                                                        Név
                                                    </div>
                                                </b-col>
                                                <b-col cols="8">
                                                    <div>{{p.description}}</div>
                                                </b-col>
                                            </b-row>
                                            <hr style="width: 80%">
                                            <b-row class="my-3" align-h="center">
                                                <b-col cols="4" align-self="center">
                                                    <div class="mx-auto font-weight-bold" style="width: fit-content;">
                                                        Db
                                                    </div>
                                                </b-col>
                                                <b-col cols="8">
                                                    <div>{{p.count}}</div>
                                                </b-col>
                                            </b-row>
                                            <hr style="width: 80%">
                                            <b-row class="my-3" align-h="center">
                                                <b-col cols="4" align-self="center">
                                                    <div class="mx-auto font-weight-bold" style="width: fit-content;">
                                                        Egységár
                                                    </div>
                                                </b-col>
                                                <b-col cols="8">
                                                    <div>{{self.formatNumberWithUnit(p.price, self.currency, false, 0)}}</div>
                                                </b-col>
                                            </b-row>
                                            <hr style="width: 80%">
                                            <b-row class="my-3" align-h="center">
                                                <b-col cols="4" align-self="center">
                                                    <div class="mx-auto font-weight-bold" style="width: fit-content;">
                                                        Összesen
                                                    </div>
                                                </b-col>
                                                <b-col cols="8">
                                                    <div>{{self.formatNumberWithUnit(p.price * p.count, self.currency, false, 0)}}
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </div>
                                <b-table v-else :items="[product]" :fields="fields" tbody-tr-class="my-class">
                                    <template v-slot:cell(item)="data">
                                        <img class="table-item-image" :src="data.item.img"/>
                                    </template>
                                    <template v-slot:cell(total)="data">
                                        {{self.formatNumberWithUnit(data.item.price * data.item.count, self.currency, false, 0)}}
                                    </template>
                                </b-table>
                                <hr style="width: 90%">
                            </b-col>
                        </b-row>
                        <b-row class="mt-lg-5 mt-0 mb-4" align-h="center">
                            <b-col cols="12" md="10" v-show="!paymentSuccess && loaded">
                                <b-form-group label="Fizetési mód" label-class="h3 mb-0 outline-0">
                                    <b-form-radio-group v-model="paymentMethod" class="outline-0">
                                        <b-form-radio v-for="method in paymentMethods" :key="method.value" :value="method.value">{{method.text}}</b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>
                                <hr>
                            </b-col>
                            <b-col cols="12" md="10" xl="6" v-show="!paymentSuccess && loaded" class="text-center">
                                <div v-show="paymentMethod === 'PAYPAL'" ref="paypal"></div>
                                <div v-show="paymentMethod === 'TRANSFER'">
                                    <InvoiceForm :form-state="formState" :form="form"  @change="checkFormState"></InvoiceForm>
                                    <b-row>
                                        <b-col cols="12">
                                            <b-button variant="primary" class="btn-lg" @click="onTransferSubmitBtn">Rendelés megerősítése</b-button>
                                        </b-col>
                                        <b-col cols="12">
                                            A 'Rendelés megerősítése' gomb megnyomása után egy bizonylatot kap, amelyet később a 'Profil' oldalon is megtekinthet.
                                            A bizonylat azonosítóját kell közleményben megadni az átutalásnál.
                                            Az összeg beérkezésétől számított 24 órán belül aktiváljuk csomagját, így elérhetővé válnak számára prémium szolgáltatásaink.
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                            <b-col cols="12" md="10" xl="8" v-if="paymentSuccess" class="text-center">
                                <div class="py-4"
                                     style="border-radius: 0.5rem; background-color: rgba(255,255,255,0.85);">
                                    <b-row>
                                        <b-col class="text-right font-weight-bold">Bizonylat azonosító:</b-col>
                                        <b-col class="text-left">{{receipt ? receipt.id : ''}}</b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col class="text-right font-weight-bold">Bizonylat időpontja:</b-col>
                                        <b-col class="text-left">{{receipt ? printDate(Math.floor(new Date(receipt.time).getTime() / 1000)) : ''}}</b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col class="text-right font-weight-bold">Státusz:</b-col>
                                        <b-col class="text-left">{{receipt ? $t('receipt.status.' + receipt.status.toLowerCase()) : ''}}</b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col class="text-right font-weight-bold">Termék:</b-col>
                                        <b-col class="text-left">{{receipt ? (receipt.product ? receipt.product.description : '') : ''}}</b-col>
                                    </b-row>
                                </div>
                                <div v-if="paymentMethod === 'TRANSFER'">
                                    <b-row class="mt-4 text-left px-2">
                                        <b-col>
                                            <div class="mb-2">Tisztelt Vásárlónk!</div>
                                            <div class="mb-2 text-justify">Köszönjük, hogy megrendelte szolgáltatásunkat. Amennyiben a befizetendő összeget még nem fizette be, kérjük tegye meg. Az összeg beérkezésétől számított 24 órán belül aktiváljuk csomagját, így elérhetővé válnak számára prémium szolgáltatásaink. Köszönjük, hogy minket választott, eredményes közös munkát kívánunk!</div>
                                            <div>Kedvezményezett neve: Göndöcs Benedek Áron EV</div>
                                            <div>Számlaszám: 11731056-21455759</div>
                                            <div>Megjegyzés: {{receipt ? receipt.id : ''}}</div>
                                        </b-col>
                                    </b-row>
                                </div>
                                <b-button variant="primary" class="mt-5 mb-3 btn-lg w-50" v-on:click="onProfileBtn">
                                    {{$t('pages.profile')}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {Alert} from '@/components';
    import store from "../../plugins/store";
    import backend from "../../plugins/backend";
    import InvoiceForm from "../profile/InvoiceForm";

    export default {
        name: "Checkout",
        data() {
            return {
                paymentMethod: 'PAYPAL',
                paymentMethods: [
                    {
                        value: 'PAYPAL',
                        text: 'PayPal/Bankkártyás fizetés'
                    },
                    {
                        value: 'TRANSFER',
                        text: 'Banki átutalás'
                    }
                ],
                paymentSuccess: false,
                paymentFailed: false,
                invalidReceipt: false,
                loaded: false,
                errors: [],
                currency: 'HUF',
                product: {},
                backBtnTitle: '<< Vissza',
                fields: [
                    {
                        key: 'item',
                        label: 'Cikk',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'description',
                        label: 'Név',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'count',
                        label: 'Darab',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'price',
                        label: 'Egységár',
                        formatter: value => {
                            return this.formatNumberWithUnit(value, this.currency, false, 0);
                        },
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'total',
                        label: 'Összesen',
                        tdClass: 'align-middle'
                    }
                ],
                token: {
                    sandbox: 'AZyVQOrLHcTb4Zo9T_HqQIIg2irEjY0ZLfVSayxMv_sLXdHs0Y0WuqdCZ7TGYCN36y3WWYb0Pn5G5UQ7',
                    live: 'AbmfnPZ6QKZfRLU2s6pM5o9fYvcWo3eUQ5asjaQTETrqz21UfoowuloapPf3WqhcliaftTtboh_YQmJB'
                },
                formState: {
                    firstName: null,
                    lastName: null,
                    city: null,
                    addressLine1: null,
                    addressLine2: null,
                    postCode: null,
                    phone: null,
                    email: null
                },
                form: {
                    firstName: '',
                    lastName: '',
                    city: '',
                    addressLine1: '',
                    addressLine2: '',
                    postCode: '',
                    phone: '',
                    email: ''
                },
                receipt: null
            };
        },
        components: {
            Alert,
            InvoiceForm
        },
        computed: {
            isMobile() {
                return this.$root.isMobile;
            },
            self() {
                return this;
            },
            loading() {
                return store.getters.isLoading;
            }
        },
        mounted() {
            this.initProduct();
            this.init();
        },
        methods: {
            init() {
                let onResolve = () => {
                    const script = document.createElement("script");
                    let token = this.token.live;
                    script.src =
                        "https://www.paypal.com/sdk/js?client-id=" + token +"&currency=" + this.currency;
                    script.addEventListener("load", this.initPayPalService);
                    document.body.appendChild(script);
                };
                let onReject = () => {
                    store.commit('setRedirect', 'shop-checkout')
                };
                this.loginCheck('login', null, onResolve, onReject);
            },
            initPayPalService: function () {
                this.loaded = true;
                window.paypal
                    .Buttons({
                        createOrder: (data, actions) => {
                            this.errors = [];
                            this.loginCheck('login');
                            return actions.order.create({
                                purchase_units: [
                                    {
                                        description: this.product.description,
                                        amount: {
                                            currency_code: this.currency,
                                            value: this.product.price
                                        }
                                    }
                                ]
                            });
                        },
                        onApprove: async (data, actions) => {
                            store.commit("setLoading", true);
                            const order = await actions.order.capture();
                            store.commit("setLoading", false);
                            this.onSuccess(order)
                        },
                        onError: err => {
                            this.onFail(err);
                        }
                    })
                    .render(this.$refs.paypal);
            },
            onSuccess(order) {
                this.paymentSuccess = true;
                this.receipt = {
                    id: order.id,
                    time: order.create_time,
                    status: 'Feldolgozva'
                };

                let body = {
                    order: order,
                    product: store.getters.getCart
                };

                backend.registerBundleByPayPal(body, store.getters.getCart.coupon).then(response => {
                    store.commit('resetCart');
                    if (!response.errors) {
                        if (!response.body.receipt.valid)
                            this.onInvalidReceipt();
                        else {
                            let rec = response.body.receipt;
                            this.receipt = {
                                id: rec.orderId,
                                time: rec.createTime,
                                status: rec.status,
                                product: rec.products
                            };
                        }
                    } else {
                        this.onInvalidReceipt(response.errors);
                    }
                }).catch(err => {
                    store.commit('resetCart');
                    this.onInvalidReceipt();
                });
            },
            onInvalidReceipt(errors = null) {
                this.invalidReceipt = true;
                this.errors = errors || [];
            },
            onFail(err) {
                this.errors = ['messages.unexpected_error'];
            },
            onProfileBtn() {
                this.$router.replace({name: "profile"});
            },
            onDismissAlert(e) {
                this.errors = this.errors.filter(element => element !== e);
            },
            initProduct() {
                let productData = store.getters.getCart;
                if (!productData.type || !productData.id || !productData.optionId) {
                    this.$router.replace({name: 'bundles'});
                }
                backend.getProduct(productData.type, productData.id, productData.optionId, productData.coupon).then(response => {
                    this.product = response.body;
                    this.product.coupon = productData.coupon;
                    this.product.type = productData.type;
                    if (!this.product.img.startsWith('http')) {
                      this.product.img = '/img/' + this.product.img;
                    }
                    this.months = this.product.months;
                }).catch(err => {
                    this.$router.replace({name: 'bundles'});
                });
            },
            checkFormState(field) {
                if (this.form.hasOwnProperty(field) && this.formState.hasOwnProperty(field)) {
                    if (field === 'postCode') {
                        this.formState[field] = this.form[field].toString().length > 0;
                    } else {
                        this.form[field] = this.form[field].trim();
                        let value = this.form[field];
                        if (field === 'phone') {
                            if (value.length === 0) {
                                this.formState[field] = null;
                            } else {
                                let result = this.isPhoneNumberValid(value);
                                this.formState[field] = result === true;
                            }
                        } else if (field === 'email') {
                            let result = this.isEmailValid(value);
                            this.formState[field] = result === true;
                        } else if (field === 'addressLine2') {
                            this.formState[field] = value.length === 0 ? null : true;
                        } else {
                            this.formState[field] = this.form[field].length > 0;
                        }
                    }
                }
            },
            onTransferSubmitBtn() {
                let submitAble = true;
                for (let propName in this.form) {
                    if (this.form.hasOwnProperty(propName)) this.checkFormState(propName);

                    if (this.formState.hasOwnProperty(propName)) {
                        submitAble = submitAble && this.formState[propName] !== false;
                    } else {
                        submitAble = false
                    }
                }

                if (submitAble) {
                    backend.requestOrder(this.form, this.product, this.product.coupon).then(response => {
                        if (response.body && !response.errors) {
                            this.paymentSuccess = true;
                            this.receipt = {
                                id: response.body.receipt.orderId,
                                time: response.body.receipt.createTime,
                                status: response.body.receipt.status,
                                product: response.body.receipt.products
                            };
                        } else {
                            this.onFail(response.errors);
                        }
                    }).catch(err => {
                    })
                }
            }
        }
    }
</script>

<style>
</style>