<template>
    <div id="app" class="overflow-hidden">
        <router-view name="header"/>
        <div class="wrapper">
            <alert v-show="adString" class="ad-bar" dismissible>
              {{adString}}
              <router-link tag="button" :to="{name: 'bundles'}"
                           class="my-0 ml-3 px-1 btn btn-golden bundle-description text-center">
                CSOMAGOK
              </router-link>
            </alert>
            <div class="refresh-container" v-if="hashChanged && $root.env !== 'development'">
              <div class="notification-body">
                <b-row class="notification-button">
                  <b-col class="text-center">
                    <span style="font-size: 1rem;">Az oldal egy új verziója elérhető, kérem frissítsen rá, hogy elkerülje a hibákat</span>
                    <button class="ml-3 btn btn-primary" @click="reloadApp">Frissítés</button>
                  </b-col>
                </b-row>
              </div>
            </div>
            <router-view/>
        </div>
        <router-view name="footer"/>
    </div>
</template>
<script>
    import backend from "./plugins/backend";
    import {Alert} from '@/components';

    export default {
        components: {
            Alert
        },
        data() {
            return {
                adString: null,
                discounts: null,
                timer: null,
                closedR: false
            }
        },
        methods: {
            updateAdString() {
                let self = this;
                let maxEnd = '';
                if (self.discounts) {
                    self.discounts.forEach(x => {
                        if (!maxEnd) maxEnd = x.end;
                        else if (x.end > maxEnd) {
                            maxEnd = x.end;
                        }
                    });
                }

                if (maxEnd) {
                    maxEnd += "+01:00";
                    let now = Math.floor(new Date().getTime() / 1000);
                    let endTime = Math.floor(new Date(maxEnd).getTime() / 1000);
                    if (now <= endTime) {
                        let diffTimeSeconds = endTime - now;
                        self.adString = '🔥 Akció 🔥 Csatlakozz hozzánk most kedvezményesen! Még érvényes: ' + this.printDurationDDHHMMSS(diffTimeSeconds);
                    }
                }
            },
            startTimer() {
                let self = this;
                self.timer = setInterval(self.updateAdString, 1000);
                self.initVersionCheck("https://sportfogadokclubja.hu/js/version.json");
            },
            stopTimer() {
                let self = this;
                if (self.timer) {
                    clearInterval(self.timer);
                    self.timer = null;
                }
            }
        },
        mounted() {
            let self = this;
            self.startTimer();
            backend.getDiscounts().then(response => {
                self.discounts = response.body;
            }).catch(err => {
            });
        },
        watch: {
            discounts() {
                this.updateAdString();
            }
        },
        beforeDestroy() {
            this.stopTimer();
        }
    };
</script>
<style scoped>
    #app {
    }

    .ad-bar {
        background: #de2e2e;
        text-align: left;
        z-index: 1;
        font-size: 16px;
        color: whitesmoke;
        padding: 18px 5%;
        font-weight: 800;
        display: block;
        position: relative;
        top: 86px;
    }

    .refresh-container {
        z-index: 10;
        width: 100%;
        position: fixed;
        font-weight: bold;
        top: 86px;
        background-color: goldenrod;
        -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
        -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
        box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
    }

</style>
