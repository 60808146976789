<template>
  <div class="profile-page">
    <div v-show="loading" class="loading-spinner">
      <b-spinner class="m-auto" variant="primary" label="Spinning"></b-spinner>
    </div>
    <parallax-page-header :src="'/img/plain_black.jpg'" :vertical-position="'top'"
                          :title="currentUser ? currentUser.name : ''" :text="getParallaxText()" :small="true"/>
    <parallax class="page-parallax-bg"></parallax>
    <b-row class="pt-5 main-content clear-filter" filter-color="profile-page-shadow" align-h="center">
      <b-col cols="12" lg="5" xl="4">
        <div class="mx-auto my-4 text-center"
             style="border-radius: 0.5rem; background-color: whitesmoke;max-width: 400px;width: 90%;">
          <div style="font-size: 3em; font-weight: bold;" class="text-primary">Aktív csomagok</div>
          <hr class="mt-0" style="border: #192537 1px solid;">
          <div v-if="activeBundles.length !== 0">
            <div v-for="bundle in activeBundles" :key="bundle.id">
              <router-link tag="a" :to="{name: 'bundle', params: {'id': bundle.id}}"
                           style="font-size: 2em; color: goldenrod;">{{ $t(bundle.title) }}
              </router-link>
              <div style="font-size: 1.2em;">(Érvényes eddig: {{ bundle.endTime }})</div>
              <hr class="w-75">
            </div>
          </div>

          <div v-if="activeBundles.length === 0">
            <div style="font-size: 2em; color: goldenrod;">Ingyenes csomag</div>
            <div style="font-size: 1.2em;">(Érvényes eddig: Örökös)</div>
            <hr class="w-75">
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="5" xl="4" v-show="mailOptions !== {}">
        <div class="mx-auto my-4 text-center"
             style="border-radius: 0.5rem; background-color: whitesmoke;max-width: 400px;width: 90%;">
          <div style="font-size: 3em; font-weight: bold;" class="text-primary">E-mail beállítások</div>
          <hr class="mt-0" style="border: #192537 1px solid;">
          <div v-for="(_, prop, index) in mailOptions" :key="index">
            <div v-if="mailOptions.hasOwnProperty(prop)">
              <b-row align-h="center" align-v="center">
                <div style="font-size: 2em;" class="mr-2 text-primary">{{ $t('mail.' + prop) }}</div>
                <b-form-checkbox @change="onMailOptionsChange" switch v-model="mailOptions[prop]"/>
              </b-row>
              <hr class="w-75">
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="5" xl="4">
        <div class="my-4 mx-auto text-center" style="max-width: 350px;width: 100%;">
          <a class="mb-4 btn-lg btn btn-golden bundle-description text-center w-100"
             style="font-size: 2em;"
             href="https://firebasestorage.googleapis.com/v0/b/sportfogadokclubja.appspot.com/o/public%2Fdoc%2FBankroll-menedzsment_utmutato.pdf?alt=media"
             target="_blank"
          >{{ $t('pages.tutorial') }}
          </a>
          <router-link tag="button" class="mb-4 btn btn-primary bundle-description text-center w-100"
                       style="font-size: 2em;"
                       :to="{name: 'bundles'}">{{ $t('profile.bundles') }}
          </router-link>
          <router-link v-if="false" tag="button"
                       class="mb-4 btn btn-primary bundle-description text-center w-100"
                       style="font-size: 2em;"
                       :to="{name: 'profile-edit'}">{{ $t('profile.edit') }}
          </router-link>
          <router-link v-if="currentUser !== null && currentUser.userRole === 'Admin'" tag="button"
                       class="mb-4 btn btn-primary bundle-description text-center w-100"
                       style="font-size: 2em;"
                       :to="{name: 'admin-panel'}">{{ $t('profile.admin') }}
          </router-link>
        </div>
      </b-col>
      <b-col cols="12" v-if="receipts ? receipts.length > 0 : false">
        <b-row align-h="center" class="overflow-auto">
          <b-col cols="12" class="text-center">
            <div class="h2 text-white">Bizonylatok</div>
          </b-col>
          <b-col cols="12" md="8">
            <b-pagination align="fill"
                          v-if="receipts ? receipts.length > receiptsTablePerPage : false"
                          v-model="receiptsTableCurrentPage"
                          :total-rows="receipts.length"
                          :per-page="receiptsTablePerPage"
                          aria-controls="receipts-table"
            >
            </b-pagination>
            <b-table id="receipts-table" dark :items="receipts" :fields="receiptFields"
                     :per-page="receiptsTablePerPage" :current-page="receiptsTableCurrentPage">
              <template v-slot:cell(actions)="data">
                <b-button variant="primary" @click="data.toggleDetails()">
                  {{ data.detailsShowing ? '&#x25B2;' : '&#x25BC;' }} Részletek
                </b-button>
              </template>
              <template v-slot:row-details="data" class="outline-0">
                <b-card class="m-0 outline-0" style="background-color: #00000044">
                  <b-row align-h="center" class="h6">
                    <b-col cols="12" md="10" xl="8">
                      <b-table small dark :items="data.item.products"
                               :fields="productFields"></b-table>
                      <hr style="border-color: grey">
                    </b-col>
                  </b-row>
                  <b-row align-h="center" class="h5">
                    <b-col cols="12" md="10" xl="8"
                           v-if="data.item.status === $t('receipt.status.completed')">
                      A termék fizetése sikeresen megtörént.
                    </b-col>
                    <b-col cols="12" md="10" xl="8"
                           v-if="data.item.status === $t('receipt.status.ordered')">
                      <div class="mb-1">Köszönjük, hogy megrendelte szolgáltatásunkat. Amennyiben
                        a befizetendő összeget még nem fizette be, kérjük tegye meg. Az összeg
                        beérkezésétől számított 24 órán belül aktiváljuk csomagját, így
                        elérhetővé válnak számára prémium szolgáltatásaink. Köszönjük, hogy
                        minket választott, eredményes közös munkát kívánunk!
                      </div>
                      <div>Kedvezményezett neve: Göndöcs Benedek EV</div>
                      <div>Számlaszám: 10918001-00000111-64520000</div>
                      <div>Megjegyzés: {{ data.item.orderId }}</div>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import store from "../../plugins/store";
import backend from "../../plugins/backend";
import ParallaxPageHeader from "../components/ParallaxPageHeader";

export default {
  name: 'profile',
  components: {
    ParallaxPageHeader
  },
  computed: {
    currentUser() {
      return store.getters.user;
    },
    loading() {
      return store.getters.isLoading;
    }
  },
  data() {
    return {
      activeBundles: [],
      receipts: [],
      receiptFields: [],
      receiptsTableCurrentPage: 1,
      receiptsTablePerPage: 10,
      productFields: [],
      mailOptions: {}
    }
  },
  methods: {
    getParallaxText() {
      if (!this.currentUser) return '';
      return this.$t('userRole.' + this.currentUser.userRole.toLowerCase()) + ' ' + this.$t('profile.user').toLowerCase();
    },
    initActiveBundles() {
      backend.getActiveBundles().then(response => {
        let list = [];
        response.body.activeBundles.forEach(bundle => {
          list.push({
            id: bundle.id,
            title: 'bundle.' + bundle.id + '.title',
            endTime: this.printDate(bundle.endTime)
          });
        });
        this.activeBundles = list;
      }).catch(err => {
      });
    },
    initReceiptFields() {
      let self = this;
      self.receiptFields = [
        {
          key: 'createTime',
          label: 'Időpont',
          sortable: true,
          formatter: (value) => {
            return self.printDate(Math.floor(new Date(value).getTime() / 1000));
          }
        },
        {
          key: 'orderId',
          label: 'Azonosító',
          sortable: true
        },
        {
          key: 'status',
          label: 'Státusz',
          sortable: true
        },
        {
          key: 'method',
          label: 'Fizetési mód',
          sortable: true
        },
        {
          key: 'valid',
          label: 'Érvényes',
          sortable: true,
          formatter: (value) => {
            return value ? 'IGEN' : 'NEM'
          }
        },
        {
          key: 'actions',
          label: 'Műveletek',
          tdClass: 'p-0'
        }
      ];
      self.productFields = [
        {
          key: 'description',
          label: 'Tétel'
        },
        {
          key: 'price',
          label: 'Ár',
          formatter: (value) => {
            return self.formatNumberWithUnit(value, 'Ft')
          }
        }
      ]
    },
    loadReceiptsForUser() {
      let self = this;
      backend.getReceiptsForUser().then(response => {
        self.receipts = response.body;
        if (self.receipts) {
          self.receipts.forEach(receipt => {
            receipt.status = self.$t('receipt.status.' + receipt.status.toLowerCase())
          })
        }
      }).catch(err => {
      })
    },
    loadMailOptions() {
      let self = this;
      backend.getMailOptions().then(response => {
        self.mailOptions = response.body.mailOptions;
      }).catch(err => {
        self.mailOptions = {};
      })
    },
    onMailOptionsChange() {
      let self = this;
      backend.setMailOptions(self.mailOptions).then(response => {
      }).catch(err => {
        self.loadMailOptions();
      })
    },
    init() {
      let self = this;
      let resolve = () => {
        self.initActiveBundles();
        self.loadReceiptsForUser();
        self.initReceiptFields();
        self.loadMailOptions();
      };
      self.loginCheck('login', null, resolve);
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style></style>
