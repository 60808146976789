<template>
    <div>
        <b-row>
            <b-col>
                <parallax-page-header class="clear-filter" src="img/plain_black.jpg" title="Fizess elő most!"
                                      :extra-big="!mobileView" :z-index="-1" :buttons="buttons" :paragraphs="paragraphs"/>

                <div class="text-white pb-5" v-if="mobileView && paragraphs.length > 0" style="background: url('img/plain_black.jpg')">
                    <b-row v-for="(p, index) in paragraphs" :key="p[0]" class="mx-2 py-2">
                        <div class="text-golden text-right mr-2" style="font-size: 4rem; line-height: 1">{{ index + 1 }}</div>
                        <b-col class="text-justify">
                            <b-row><div class="text-golden h3 m-0">{{ p[0] }}</div></b-row>
                            <b-row><div class="h5 m-0">{{ p[1] }}</div></b-row>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
        <b-row class="index-page mx-0" v-if="!mobileView">
            <router-link tag="div" :to="{name: 'introduction'}" class="grid-image overflow-hidden" style="width: 27%;">
                <div class="w-100 h-100 router-image-container"
                     style="background-image: url('/img/hero-vegas.jpg');"></div>
                <div class="w-100 h-100 router-image-shadow">
                    <div class="on-grid-text" style="bottom: 21%; right: 10%">{{ $t('pages.introduction') }}</div>
                </div>
            </router-link>
            <router-link tag="div" :to="{name: 'statistics'}" class="grid-image overflow-hidden" style="width: 21%;">
                <div class="w-100 h-100 router-image-container"
                     style="background-image: url('/img/hero-statistics.jpg');"></div>
                <div class="w-100 h-100 router-image-shadow">
                    <div class="on-grid-text">{{ $t('pages.statistics') }}</div>
                </div>
            </router-link>
            <router-link tag="div" :to="{name: 'bundles'}" class="grid-image overflow-hidden" style="width: 21%;">
                <div class="w-100 h-100 router-image-container"
                     style="background-image: url('/img/hero-money.jpg');"></div>
                <div class="w-100 h-100 router-image-shadow">
                    <div class="on-grid-text">{{ $t('pages.bundles') }}</div>
                </div>
            </router-link>
            <div class="grid-image overflow-hidden" style="width: 27%; margin-left: -0.3%">
                <div class="w-100 h-100 router-image-container"
                     style="background-image: url('/img/plain_black.jpg');"></div>
                <div class="w-100 h-100 router-image-shadow">
                    <div class="on-grid-text" style="bottom: 21%;">{{ $t('pages.comingSoon') }}</div>
                </div>
            </div>
        </b-row>
        <b-row>
            <div class="m-index-page col" v-if="mobileView">
                <router-link tag="div" :to="{name: 'introduction'}" class="grid-image overflow-hidden"
                             style="height: calc(25vh - 21.5px + 48px); margin-top: -82px;">
                    <div class="w-100 h-100 router-image-container router-image-container-top"
                         style="background-image: url('/img/hero-vegas.jpg');"></div>
                    <div class="w-100 h-100 router-image-shadow">
                        <div class="on-grid-text" style="bottom: 25%;">{{ $t('pages.introduction') }}</div>
                    </div>
                </router-link>
                <router-link tag="div" :to="{name: 'statistics'}" class="grid-image overflow-hidden">
                    <div class="w-100 h-100 router-image-container"
                         style="background-image: url('/img/hero-statistics.jpg');"></div>
                    <div class="w-100 h-100 router-image-shadow">
                        <div class="on-grid-text">{{ $t('pages.statistics') }}</div>
                    </div>
                </router-link>
                <router-link tag="div" :to="{name: 'bundles'}" class="grid-image overflow-hidden">
                    <div class="w-100 h-100 router-image-container"
                         style="background-image: url('/img/hero-money.jpg');"></div>
                    <div class="w-100 h-100 router-image-shadow">
                        <div class="on-grid-text">{{ $t('pages.bundles') }}</div>
                    </div>
                </router-link>
                <div class="grid-image overflow-hidden" style="height: calc(25vh - 21.5px + 48px);">
                    <div class="w-100 h-100 router-image-container"
                         style="background-image: url('/img/plain_black.jpg');"></div>
                    <div class="w-100 h-100 router-image-shadow">
                        <div class="on-grid-text" style="bottom: 130px;">{{ $t('pages.comingSoon') }}</div>
                    </div>
                </div>
            </div>
        </b-row>
    </div>
</template>
<script>

import ParallaxPageHeader from "@/pages/components/ParallaxPageHeader";

export default {
    name: 'index',
    components: {
        ParallaxPageHeader
    },
    computed: {
        windowWidth() {
            return this.$root.windowWidth;
        },
        windowHeight() {
            return this.$root.windowHeight;
        }
    },
    data() {
        return {
            mobileView: true,
            paragraphs: [
                ['Hosszútávú profit', 'Csapatunk több, mint egy éve profitál előfizetői számára'],
                ['Információkon alapuló tippek', 'Soha nem hasraütésszerűen adunk fogadási tanácsot, mint a legtöbb oldal! Mindig megfontolt, belső információkon alapulnak tippjeink, mellyel bizonyítottuk, hogy hosszútávon ezzel a módszerrel igenis profitábilis a sportfogadás'],
                ['Sportfogadó közösség', 'Telegram csoportunk sok olyan taggal rendelkezik, akik hosszú hónapok óta bizalommal fizetnek elő hozzánk, nem hiába, így kialakult egy remek közösség is']
            ],
            buttons: [
                {
                    variant: 'golden',
                    text: 'Előfizetek!',
                    color: 'primary',
                    link: 'bundles'
                },
                {
                    variant: 'primary',
                    text: 'Stratégiai útmutató',
                    color: 'white',
                    link: 'profile'
                }
            ]
        }
    },
    methods: {
        checkMobileView() {
            let ratio = this.windowWidth / this.windowHeight;
            this.mobileView = ratio < 1.2;
        }
    },
    mounted() {
        this.checkMobileView();
    },
    watch: {
        windowWidth() {
            this.checkMobileView();
        },
        windowHeight() {
            this.checkMobileView();
        }
    }
};
</script>
<style>

</style>
