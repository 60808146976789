<template>
    <component
            class="dropdown"
            :is="tag"
            :class="[
      { show: isOpen },
      { dropdown: direction === 'down' },
      { dropup: direction === 'up' }
    ]"
            aria-haspopup="true"
            :aria-expanded="isOpen"
            @mouseover="openDropDown"
            @mouseleave="closeDropDown"
    >
        <slot name="title">
            <router-link v-if="nav" :to="{name: to}" class="dropdown-toggle nav-link"
                         :class="{ 'no-caret': hideArrow }"
                         data-toggle="dropdown">
                <i :class="icon"></i>
                <span class="no-icon">{{ title }}</span>
            </router-link>
            <a v-else
               class="dropdown-toggle nav-link"
               :class="{ 'no-caret': hideArrow }"
               data-toggle="dropdown"
            >
                <i :class="icon"></i>
                <span class="no-icon">{{ title }}</span>
            </a>
        </slot>
        <ul
                class="dropdown-menu dropdown-primary"
                :class="[
        { 'dropdown-menu-right': position === 'right' },
        { show: isOpen }
      ]"
        >
            <slot></slot>
        </ul>
    </component>
</template>
<script>
    export default {
        name: 'drop-down-hover',
        props: {
            direction: {
                type: String,
                default: 'down'
            },
            title: String,
            nav: Boolean,
            to: String,
            icon: String,
            position: String,
            hideArrow: Boolean,
            tag: {
                type: String,
                default: 'li'
            }
        },
        data() {
            return {
                isOpen: false
            };
        },
        provide() {
            return {
                closeDropDown: this.closeDropDown
            }
        },
        methods: {
            openDropDown() {
                this.isOpen = true;
                this.$emit('change', this.isOpen);
            },
            closeDropDown() {
                this.isOpen = false;
                this.$emit('change', this.isOpen);
            }
        }
    };
</script>
<style>
    .dropdown {
        list-style-type: none;
    }

    .dropdown .dropdown-toggle {
        cursor: pointer;
    }
</style>
