<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav class="col-sm-12 col-md-6 text-left">
        <ul>
          <li>
            <a href="https://www.facebook.com/SportfogadokClubja" target="_blank">
              <i class="fab fa-facebook"></i> Facebook
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/sportfogadokclubja" target="_blank">
              <i class="fab fa-instagram"></i> Instagram
            </a>
          </li>
          <li>
            <a href="https://t.me/SportfogadokClubja" target="_blank">
              <i class="fab fa-telegram"></i> Telegram
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright col-lg-4 col-sm-12 text-right">
        &copy; {{ year }} {{$t('siteName')}}™
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
