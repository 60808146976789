<template>
    <div>
        <div class="bundles-body">
            <div v-show="loading" class="loading-spinner">
                <b-spinner class="m-auto" variant="primary" label="Spinning"></b-spinner>
            </div>
            <div class="page-header clear-filter" filter-color="bundles-cover-shadow"
                 :class="{'page-header-small': false}">
                <parallax
                    class="page-header-image"
                    :style="[{'z-index': '-4'},{'height': '300%'},{'width': '220%'}]"
                >
                    <div class="bundles-parallax" :class="{'m-bundles-parallax': isMobile}"></div>
                </parallax>
                <div class="content-center">
                    <div class="container">
                        <h1 v-if="title" class="title">{{ $t(title) }}</h1>
                        <h3 v-if="text" class="text-center">{{ $t(text) }}</h3>
                    </div>
                </div>
            </div>
            <div v-show="isMobile">
                <HexaTiles style="position: absolute; top: 12rem; left: 0;z-index: -5;" :count="20" :size="9"
                           :group-count="4"
                           :min-left="2" :max-left="7"></HexaTiles>
            </div>
            <div v-show="!isMobile">
                <HexaTiles style="position: absolute; top: 12rem; left: 0;z-index: -5;" :count="5" :size="14"
                           :group-count="2"
                           :min-left="0" :max-left="1"></HexaTiles>
                <HexaTiles style="position: absolute; top: 12rem; right: 0;z-index: -5;" :count="5" :size="14"
                           :group-count="2"
                           :min-left="-3" :max-left="-1"></HexaTiles>
            </div>
            <parallax class="page-parallax-bg"></parallax>
            <b-row class="items-container mx-auto clear-filter" filter-color="bundles-page-shadow">
                <b-col class="items-container-2">
                      <b-row class="bundle-main">
                        <b-col class="bundle-secondary">
                          <b-tabs align="center" pills card>
                            <b-tab v-for="tab in tabs" :key="tab.key" @click="tabClick(tab.key)" :active="hash.includes(tab.key)">
                              <b-row v-for="bundle in bundles.filter(x => {return x.type === 'all' || x.type === tab.key;})" :key="bundle.bundleId" class="bundle-row"
                                     :class="[{'w-100': isMobile}]" align-v="center">
                                <b-col cols="10" sm="9" md="8" lg="6" xl="4" class="mx-auto">
                                  <div class="bundle-image-container"
                                       :style="'background-image: url(' + bundle.img + ');'">
                                  </div>
                                  <b-row class="mx-0">
                                    <b-col lg="8" class="pl-0 pr-1">
                                      <router-link tag="button" :disabled="disabledShop(bundle.bundleId)"
                                                   class="mb-0 px-1 btn bundle-description text-center w-100"
                                                   :class="{'btn-secondary': disabledShop(bundle.bundleId), 'btn-golden': !disabledShop(bundle.bundleId)}"
                                                   :to="{name: 'shop-cart', params: {productId: bundle.bundleId, type: 'bundle'}}">
                                        <i class="fa fa-shopping-cart mr-2"></i> Vásárlás
                                      </router-link>
                                    </b-col>
                                    <b-col lg="4" class="pl-1 pr-0 mb-4 mb-md-0">
                                      <router-link tag="button"
                                                   class="btn btn-primary bundle-description text-center w-100"
                                                   :to="{name: 'bundle', params: {id: bundle.bundleId}}">
                                        Leírás
                                      </router-link>
                                    </b-col>
                                  </b-row>

                                </b-col>
                                <b-col cols="12" sm="9" md="9" lg="6" xl="8" class="align-self-start mx-auto">
                                  <div>
                                    <div class="float-right">
                                      <div v-if="bundle.limit" style="border-radius: 0.5rem; background-color: #192537;
                                            color: goldenrod; box-shadow: 0 5px 25px 0 rgba(100, 100, 100, 0.2);"
                                           class="bundle-description px-2 py-2 font-weight-bold text-center">
                                        {{ getLimitInfo(bundle.bundleId) }}
                                      </div>
                                    </div>
                                    <b-row>
                                      <router-link tag="a" :to="{name: 'bundle', params: {id: bundle.bundleId}}"
                                                   class="bundle-nav bundle-title">{{ bundle.title }}
                                      </router-link>
                                      <b-tabs class="ml-lg-4 ml-md-2" pills>
                                        <b-tab v-for="option in bundle.options" :key="option.optionId"
                                               @click="selectOption(bundle.bundleId, option.optionId)">
                                          <template #title>
                                            <div>{{ option.days + ' NAP' }}</div>
                                          </template>
                                        </b-tab>
                                      </b-tabs>
                                    </b-row>
                                  </div>
                                  <ul class="bundle-description">
                                    <li v-if="bundle.selectedOption.price !== bundle.selectedOption.originalPrice || bundle.selectedOption.days !== bundle.selectedOption.originalDays">
                                      <span class="text-danger font-weight-bold">AKCIÓS </span>
                                      <span
                                          v-if="bundle.selectedOption.days !== bundle.selectedOption.originalDays">
                                                <span
                                                    class="text-strikethrough">({{ bundle.selectedOption.originalDays }})</span>
                                                <span
                                                    class="text-golden font-weight-bold"> {{ bundle.selectedOption.days }} nap: </span>
                                            </span>
                                      <span v-else>
                                                {{ bundle.selectedOption.days }} nap:
                                            </span>

                                      <span
                                          v-if="bundle.selectedOption.originalPrice !== bundle.selectedOption.price">
                                                <span
                                                    class="text-strikethrough">({{ self.formatNumberWithUnit(bundle.selectedOption.originalPrice, '', false, 0) }})</span>
                                                <span
                                                    class="text-golden font-weight-bold"> {{ self.formatNumberWithUnit(bundle.selectedOption.price, 'Ft', false, 0) }}</span>
                                            </span>
                                      <span v-else>
                                                {{ self.formatNumberWithUnit(bundle.selectedOption.price, 'Ft', false, 0) }}
                                            </span>
                                    </li>
                                    <li v-else>{{ bundle.selectedOption.days }} nap:
                                      {{ self.formatNumberWithUnit(bundle.selectedOption.price, 'Ft', false, 0) }}
                                    </li>
                                    <li v-if="bundle.odds">Átlag odds: {{ bundle.odds }}</li>
                                    <li v-if="bundle.stake">Tétméret: {{ bundle.stake }}</li>
                                    <li v-if="bundle.amount">Havi átlag tippmennyiség:
                                      {{ self.formatNumberWithUnit(bundle.amount, 'db', false, 0) }}
                                    </li>
                                    <li v-if="bundle.sports">Sportágak: {{ bundle.sports }}</li>
                                    <li v-if="bundle.services && bundle.services.length > 0">
                                      {{ bundle.services[0] }}
                                    </li>
                                  </ul>
                                </b-col>
                              </b-row>
                              <template #title>
                                <div>{{ tab.title }}</div>
                              </template>
                            </b-tab>
                          </b-tabs>
                        </b-col>
                      </b-row>


                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import HexaTiles from "../components/HexaTiles";
import backend from "../../plugins/backend";
import store from "../../plugins/store";
import scRouter from "@/scRouter";

export default {
    name: "bundles",
    components: {
        HexaTiles
    },
    computed: {
        isMobile() {
            return this.$root.isMobile;
        },
        self() {
            return this;
        },
        loading() {
            return store.getters.isLoading;
        },
        currentUser() {
            return store.getters.user;
        },
        isAdmin() {
            let isAdmin = false;
            if (this.currentUser && this.currentUser.userRole) {
                isAdmin = this.currentUser.userRole.toUpperCase() === 'ADMIN';
            }
            return isAdmin;
        },
        hash() {
          return location.hash;
        }
    },
    data() {
        return {
            heroSrc: '/img/hero-collage.jpg',
            title: 'bundles.title',
            text: 'bundles.subtitle',
            bundles: [],
            bundleConfigs: [],
            tabs: [
                {key: 'sc', title: 'Sportfogadók Clubja'},
              {key: 'mustang', title: 'Mustang Horse Racing'}
            ]
        };
    },
    methods: {
        tabClick(hash) {
          scRouter.push({hash: hash})
        },
        initBundles() {
            let self = this;
            backend.getBundleConfigs().then(response => {
                this.bundleConfigs = response.body
            }).catch(err => {
            })

            backend.getBundles().then(response => {
                this.bundles = response.body.map(b => {
                    b.selectedOption = b.options[0];
                    if (!b.img.startsWith('http')) {
                      b.img = '/img/' + b.img;
                    }
                    return b;
                }).filter(b => {
                    return b.bundleId !== 'test' || self.isAdmin;
                })
              this.bundles.sort((a, b) => {return a.options[0].price - b.options[0].price;})
            }).catch(err => {
            });
        },
        getLimitInfo(bundleId) {
            let config = this.bundleConfigs.find(x => {
                return x.type === bundleId
            })
            if (config) return config.currentValue + '/' + config.limit + ' Fő'
            else return ''
        },
        disabledShop(bundleId) {
            let config = this.bundleConfigs.find(x => {
                return x.type === bundleId
            })
            if (!config) return false
            else {
                return config.currentValue >= config.limit
            }
        },
        selectOption(bundleId, optionId) {
            let self = this;
            let b = self.bundles.find(x => {
                return x.bundleId === bundleId
            });
            b.selectedOption = b.options.find(x => {
                return x.optionId === optionId
            });
        }
    },
    mounted() {
        this.initBundles();
        if (!location.hash) {
          scRouter.push({hash: 'sc'});
        }
    }
}
</script>

<style scoped>
.bundle-nav {
    color: inherit;
}

.bundle-nav:hover {
    cursor: pointer;
}

.page-header {
    min-height: 20vh !important;
}
</style>