<template>
    <div class="tips-body">
        <div v-show="loading" class="loading-spinner">
            <b-spinner class="m-auto" variant="primary" label="Spinning"></b-spinner>
        </div>
        <parallax-page-header :src="heroSrc" :title="title" :vertical-position="parallaxVerticalPosition"/>
        <parallax class="page-parallax-bg"></parallax>
        <b-row class="items-container mx-auto" style="position: relative;">
            <div class="background-hack clear-filter" filter-color="tips-page-shadow"></div>
            <b-col class="px-0 mx-0">
                <b-row align-h="center">
                    <b-col style="cursor: pointer" v-if="sendSuccess" cols="8" class="text-center"
                           @click="sendSuccess = false">
                        <alert class="h5 my-1" type="info">
                            <strong>Sikeres küldés</strong>
                        </alert>
                    </b-col>
                    <b-col style="cursor: pointer" v-if="sendFailed" cols="8" class="text-center"
                           @click="sendFailed = false">
                        <alert class="h5 my-1" type="danger">
                            <strong>Sikertelen küldés</strong>
                        </alert>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <MailPreview :mail="mail"/>
                    </b-col>
                </b-row>
                <b-row align-h="center">
                    <b-col cols="12" md="10">
                        <b-card>
                            <b-row>
                                <b-col cols="12">
                                    <div class="h3 font-weight-bold text-primary">Szerkesztő felület</div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <div class="h4 mt-0 font-weight-bold text-primary">Címke kiválasztás<br>Ez alapján
                                        dől el, kiknek küldjük ki a levelet.
                                    </div>
                                </b-col>
                                <b-col v-for="tag in availableTags" :key="tag" cols="3">
                                    <b-button class="w-100 h6"
                                              :variant="tag === mail.tagType ? 'primary' : 'secondary'"
                                              style="height: 100px; font-size: 1.2em;" @click="setMailTag(tag)"
                                              :style="{'color': tag === mail.tagType ? 'white' : 'black'}">
                                        {{ tag }}
                                    </b-button>
                                </b-col>
                                <b-col v-show="mail.tagType === 'Saját címke (Személyes)'" cols="12">
                                    <b-form-input style="border-color: black;font-size: 1.25rem;" type="text"
                                                  v-model="mail.tag"></b-form-input>
                                </b-col>
                            </b-row>
                            <hr style="border-color: black; border-width: 2px;">
                            <b-row>
                                <b-col cols="12">
                                    <div class="h4 mt-0 font-weight-bold text-primary">Tárgy</div>
                                </b-col>
                            </b-row>
                            <b-row style="font-size: 1.25rem">
                                <b-col>
                                    <b-input v-model="mail.subject"></b-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" class="mt-2">
                                    <div class="h4 mt-0 font-weight-bold text-primary mb-0">Köszönés</div>
                                    <b-row align-v="center">
                                        <div style="font-size: 1.25rem" class="ml-3 mr-2 my-2">Megszólítás név szerint:</div>
                                        <b-form-checkbox switch v-model="mail['withName']" />
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row style="font-size: 1.25rem">
                                <b-col>
                                    <b-input v-model="mail.greeting"></b-input>
                                </b-col>
                            </b-row>
                            <b-row style="font-size: 1.25rem">
                                <b-col>
                                    <b-input v-model="mail.greetingExtra"></b-input>
                                </b-col>
                            </b-row>
                            <hr style="border-color: black; border-width: 2px;">
                            <b-row>
                                <b-col cols="12">
                                    <div class="h4 mt-0 font-weight-bold text-primary">Tartalom</div>
                                </b-col>
                                <b-col class="h5 font-weight-bold" v-for="(paragraph, index) in mail.paragraphs"
                                       :key="index" cols="12"
                                       style="background-color: rgba(25,37,55,0.05);">
                                    <b-row class="my-1">
                                        <b-col cols="8" md="9">{{ '#' + (1 + index) + ' Bekezdés' }}</b-col>
                                        <b-col cols="4" md="3">
                                            <b-button class="w-100 h6" variant="danger" @click="deleteParagraph(index)">
                                                <i class="fa fa-minus-circle mr-2"></i> Bekezdés törlése
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <b-form-textarea style="border: 1px solid black;" rows="6"
                                                             v-model="paragraph.text"></b-form-textarea>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col class="px-2" cols="12">
                                    <b-button class="h5" variant="primary" @click="addNewEmptyParagraph"><i
                                        class="fa fa-plus-circle mr-2"></i>Bekezdés hozzáadása
                                    </b-button>
                                </b-col>
                            </b-row>
                            <hr style="border-color: black; border-width: 2px;">
                            <b-row class="my-1 h5 font-weight-bold">
                                <b-col cols="12">Elköszönés</b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-textarea style="border: 1px solid black;" :style="defaultFarewellStyle"
                                                     rows="6"
                                                     v-model="mail.farewell"></b-form-textarea>
                                </b-col>
                            </b-row>
                            <hr style="border-color: black; border-width: 2px;">
                            <b-row class="my-1 h5 font-weight-bold">
                                <b-col cols="12">Lábrész</b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-textarea style="border: 1px solid black;" :style="defaultFooterStyle"
                                                     rows="6"
                                                     v-model="mail.footer"></b-form-textarea>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="my-2 px-2" v-for="img in availableImages" :key="img" cols="6" sm="4"
                                       md="4" lg="3" xl="2">
                                    <div class="mail-footer-img"
                                         :class="{'mail-footer-img-active': img === mail.footerImg}"
                                         :style="'background-image: url(' + '/img/' + img + ');'"
                                         @click="onFooterImgClick(img)"></div>
                                </b-col>
                                <b-col cols="12">
                                    <b-row class="h5">
                                        <b-col cols="12">
                                            <b-form-checkbox v-model="customImageCheckbox"
                                                             @change="customImageCheckboxChecked">Egyedi kép URL
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col v-show="customImageCheckbox" cols="12" md="8">
                                            <b-form-input style="border-color: black" type="text"
                                                          v-model="mail.footerImg"></b-form-input>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <hr style="border-color: black; border-width: 2px;">
                            <b-row align-h="center">
                                <b-col cols="4" class="text-center">
                                    <b-button class="w-100 h5 font-weight-bold" variant="danger" @click="onDeleteBtn">
                                        Törlés
                                    </b-button>
                                </b-col>
                                <b-col cols="4" class="text-center">
                                    <b-button class="w-100 h5 font-weight-bold" variant="success" @click="onSubmitBtn">
                                        Küldés
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {Alert} from '@/components';
import ParallaxPageHeader from "../components/ParallaxPageHeader";
import store from "../../plugins/store";
import backend from "../../plugins/backend";
import MailPreview from "@/pages/mail/MailPreview";

export default {
    name: "Mail",
    components: {
        ParallaxPageHeader,
        Alert,
        MailPreview
    },
    computed: {
        loading() {
            return store.getters.isLoading;
        },
        self() {
            return this;
        },
        currentUser() {
            return store.getters.user;
        }
    },
    data() {
        return {
            heroSrc: '/img/bg3.jpg',
            title: 'mail.title',
            parallaxVerticalPosition: '80%',
            mail: {},
            availableTags: [
                'Hírlevél',
                'Promóció',
                'Saját címke (Személyes)',
                'Admin'
            ],
            timer: '',
            defaultGreetingStyle: {
                'font-family': 'Arial',
                'text-align': 'justify',
                'font-size': '1rem',
                'font-weight': 300,
                'color': '#444',
                'margin-bottom': '1rem'
            },
            defaultFarewellStyle: {
                'font-family': 'Arial',
                'text-align': 'justify',
                'font-size': '1rem',
                'font-weight': 300,
                'color': '#444',
                'margin-top': '2rem'
            },
            defaultFooterStyle: {
                'font-family': 'Arial',
                'text-align': 'justify',
                'font-size': '1rem',
                'font-weight': 300,
                'color': '#444',
                'margin-top': '2rem',
            },
            defaultParagraphStyle: {
                'font-family': 'Arial',
                'text-align': 'justify',
                'font-size': '1rem',
                'font-weight': 300,
                'color': '#444',
                'margin-top': '0.5rem',
                'margin-bottom': '0.5rem'
            },
            defaultFooterImageStyle: {
                'margin-top': '0.5rem',
                'text-align': 'center',
                'margin-right': 'auto',
                'width': '480px',
                'height': '200px',
                'overflow': 'hidden',
                'background-position': 'center',
                'background-size': 'cover',
                'background-repeat': 'no-repeat',
                'border-radius': '0.25rem'
            },
            sendSuccess: false,
            sendFailed: false,
            availableImages: [
                'adidas_heaven.jpg',
                'bundle_big.jpg',
                'hero-vegas.jpg'
            ],
            customImageCheckbox: false
        }
    },
    methods: {
        setMailTag(tag) {
            this.$set(this.mail, 'tagType', tag);
            if (tag === 'Saját címke (Személyes)') {
                this.$set(this.mail, 'tag', 'Sportfogadók Clubja');
            } else {
                this.$set(this.mail, 'tag', tag);
            }
        },
        customImageCheckboxChecked(checked) {
            if (checked) {
                this.setFooterImg(this.customImageUrl);
            } else {
                this.customImageUrl = this.mail.footerImg;
                this.setFooterImg('bundle_big.jpg');
            }
        },
        onFooterImgClick(img) {
            if (this.customImageCheckbox) {
                this.customImageUrl = this.mail.footerImg;
                this.customImageCheckbox = false;
            }

            this.setFooterImg(img);
        },
        setFooterImg(img) {
            this.$set(this.mail, 'footerImg', img);
        },
        addNewEmptyParagraph() {
            let self = this;
            if (!self.mail.paragraphs) {
                self.$set(self.mail, 'paragraphs', [{style: self.defaultParagraphStyle, text: ''}]);
            } else {
                self.$set(self.mail.paragraphs, self.mail.paragraphs.length, {
                    style: self.defaultParagraphStyle,
                    text: ''
                });
            }
        },
        deleteParagraph(index) {
            if (index > -1) {
                this.mail.paragraphs.splice(index, 1);
            }
        },
        checkLogin() {
            let self = this;
            let onReject = () => {
                store.commit('setRedirect', 'admin-panel')
            };
            let onResolve = () => {
                if (self.currentUser.userRole.toLowerCase() !== 'admin') {
                    self.$router.replace({name: 'profile'});
                }
            };
            this.loginCheck('login', null, onResolve, onReject)
        },
        startLoginCheckTimer() {
            if (this.timer) clearInterval(this.timer);
            this.timer = setInterval(this.checkLogin, 60000)
        },
        stopLoginCheckTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
        },
        initDefaultEmptyMail() {
            let self = this;
            self.mail = {
                tag: '',
                subject: '',
                withName: true,
                greeting: 'Kedves',
                greetingExtra: '',
                greetingStyle: self.defaultGreetingStyle,
                paragraphs: [{style: self.defaultParagraphStyle, text: ''}],
                farewell: 'Üdvözlettel,\nSportfogadók Clubja csapata',
                farewellStyle: self.defaultFarewellStyle,
                footer: 'Weboldalunk: https://www.sportfogadokclubja.hu/\n' +
                    'Facebook oldalunk: https://www.facebook.com/SportfogadokClubja',
                footerStyle: self.defaultFooterStyle,
                footerImg: 'hero-vegas.jpg',
                footerImgStyle: self.defaultFooterImageStyle
            };
            self.setMailTag('Hírlevél');
        },
        onSubmitBtn() {
            let self = this;
            self.sendSuccess = false;
            self.sendFailed = false;
            backend.sendMail(self.mail).then(response => {
                self.sendSuccess = true;
                self.initDefaultEmptyMail();
            }).catch(err => {
                self.sendFailed = true;
            });
        },
        onDeleteBtn() {
            let self = this;
            self.sendSuccess = false;
            self.sendFailed = false;
            self.initDefaultEmptyMail();
        }
    },
    mounted() {
        this.checkLogin();
        this.startLoginCheckTimer();
        this.initDefaultEmptyMail();
    },
    beforeDestroy() {
        this.stopLoginCheckTimer();
    }
}
</script>

<style scoped>
.mail-footer-img {
    text-align: center;
    margin: auto;
    width: 100%;
    height: 120px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0.25rem;
}

.mail-footer-img:hover {
    cursor: pointer;
}

.mail-footer-img-active {
    border: red 0.25rem solid;
}
</style>