import backend from "./backend";
import store from "./store";
const xlsx = require('xlsx');

Date.prototype.getWeekNumber = function () {
    let d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    let dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
};

export default {
    methods: {
        getStringIntervalByWeekNum(weekNum, year = (new Date().getUTCFullYear())) {
            let currentDate = new Date(Date.UTC(year, 0, 1));
            currentDate.setUTCDate(((weekNum - 1) * 7) + 1);

            while (weekNum === currentDate.getWeekNumber()) {
                currentDate.setUTCDate(currentDate.getUTCDate() - 1);
            }
            currentDate.setUTCDate(currentDate.getUTCDate() + 1);
            let start = this.printDateYYYYMMDD(currentDate.getTime() / 1000);
            currentDate.setUTCDate(currentDate.getUTCDate() + 6);
            let end = this.printDateYYYYMMDD(currentDate.getTime() / 1000);

            return {
                start: start,
                end: end
            }
        },
        roundDouble(value, decimals = 3) {
            let rounder = Math.pow(10, decimals);
            return Math.round(value * rounder) / rounder;
        },
        formatNumberWithUnit(amount, unit = '', front = false, minDecimalCount = 2) {
            amount = Math.round(amount * 100.0) / 100.0;
            amount = amount.toString();

            let result = "";
            let counter = 0;
            let decimals = "";
            if (amount.includes('.')) {
                let split = amount.split('.');
                amount = split[0];
                decimals = split[1];
            }
            for (let i = amount.length - 1; i >= 0; i--) {
                if (counter === 3) {
                    result += " ";
                    counter = 0;
                }
                result += amount[i];
                counter++;
            }
            result = this.reverseString(result);
            while (decimals.length < minDecimalCount) {
                decimals = decimals + '0'
            }
            if (decimals) {
                result += '.' + decimals;
            }
            if (unit) {
                if (front) {
                    return unit + ' ' + result;
                } else {
                    return result + ' ' + unit;
                }
            } else {
                return result;
            }
        },
        reverseString(str) {
            return str.split("").reverse().join("");
        },
        loginCheck(redirectName, props = null, resolve = () => {
        }, reject = () => {
        }) {
            let self = this;
            let redirectTo = "";
            if (redirectName) redirectTo = redirectName;
            let loggedInUser = store.getters.user;
            if (loggedInUser) {
                backend.checkLogin().then(response => {
                    if (response.body.loggedIn) {
                        loggedInUser.userRole = response.body.userRole;
                        store.commit("setCurrentUser", loggedInUser);
                        if (resolve) resolve();
                    } else {
                        store.commit("setCurrentUser", null);
                        if (reject) reject();
                        if (redirectTo) self.$router.replace({name: redirectTo});
                    }
                }).catch(error => {
                    store.commit("setCurrentUser", null);
                    if (reject) reject();
                    if (redirectTo) self.$router.replace({name: redirectTo});
                });
            } else {
                if (reject) reject();
                if (redirectTo) self.$router.replace({name: redirectTo});
            }
        },
        alreadyLoggedInCheck(redirectName = 'index', props = null) {
            let self = this;
            let redirectTo = "index";
            if (redirectName) redirectTo = redirectName;

            let loggedInUser = store.getters.user;
            if (loggedInUser) {
                backend.checkLogin().then(response => {
                    if (response.body.loggedIn) {
                        loggedInUser.userRole = response.body.userRole;
                        store.commit("setCurrentUser", loggedInUser);
                        self.$router.replace({name: redirectTo});
                    } else {
                        store.commit("setCurrentUser", null);
                    }
                }).catch(error => {
                    store.commit("setCurrentUser", null);
                });
            }
        },
        printDate(epochSeconds) {
            let date = new Date(epochSeconds * 1000);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            if (month > 12) month = month - 12;
            let monthStr = '';
            if (month < 10) monthStr += '0';
            monthStr += month;

            let day = date.getDate();
            let dayStr = '';
            if (day < 10) dayStr += '0';
            dayStr += day;

            let hours = date.getHours();
            if (hours < 10) hours = '0' + hours;

            let minutes = date.getMinutes();
            if (minutes < 10) minutes = '0' + minutes;

            return year + '-' + monthStr + '-' + dayStr + ' ' + hours + ':' + minutes;
        },
        printDateYYYYMMDD(epochSeconds) {
            let date = new Date(epochSeconds * 1000);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            if (month > 12) month = month - 12;
            let monthStr = '';
            if (month < 10) monthStr += '0';
            monthStr += month;

            let day = date.getDate();
            let dayStr = '';
            if (day < 10) dayStr += '0';
            dayStr += day;
            return year + '-' + monthStr + '-' + dayStr;
        },
        printTimeHHMM(epochSeconds) {
            let date = new Date(epochSeconds * 1000);
            let hours = date.getHours();
            if (hours < 10) hours = '0' + hours;

            let minutes = date.getMinutes();
            if (minutes < 10) minutes = '0' + minutes;

            return hours + ':' + minutes;
        },
        printDurationDDHHMMSS(epochSeconds) {
            let days = Math.floor(epochSeconds / 86400);
            let remaining = epochSeconds - (days * 86400);
            let hours = Math.floor(remaining / 3600);
            remaining = remaining - (hours * 3600);
            let minutes = Math.floor(remaining / 60);
            remaining = remaining - (minutes * 60);
            let seconds = remaining;

            if (hours < 10) hours = '0' + hours;
            if (minutes < 10) minutes = '0' + minutes;
            if (seconds < 10) seconds = '0' + seconds;
            return days + ':' + hours + ':' + minutes + ':' + seconds;
        },
        sumNumberList(values) {
            let sum = 0.0;
            values.forEach(value => {
                sum += value;
            });
            return sum;
        },
        avgNumberList(values) {
            let avg = 0;
            if (values.length > 0) {
                let sum = this.sumNumberList(values);
                avg = sum / values.length;
            }
            return avg;
        },
        getPreviousMonthOfYYYYMM(string) {
            let split = string.split('-');
            let year = Number(split[0]);
            let month = Number(split[1]);

            let prevMonth = month - 1;
            let prevYear = year;
            if (prevMonth === 0) {
                prevMonth = 12;
                prevYear = year - 1;
            }

            if (prevMonth < 10) prevMonth = '0' + prevMonth;
            return prevYear + '-' + prevMonth;
        },
        getNextMonthOfYYYYMM(string) {
            let split = string.split('-');
            let year = Number(split[0]);
            let month = Number(split[1]);

            let nextMonth = month + 1;
            let nextYear = year;
            if (nextMonth === 13) {
                nextMonth = 1;
                nextYear = year + 1;
            }

            if (nextMonth < 10) nextMonth = '0' + nextMonth;
            return nextYear + '-' + nextMonth;
        },
        getMonthNameByNumber(month) {
            let months = [
                '',
                'Január',
                'Február',
                'Március',
                'Április',
                'Május',
                'Június',
                'Július',
                'Augusztus',
                'Szeptember',
                'Október',
                'November',
                'December',
            ];
            return months[month];
        },
        printYYYYMMtoYYYYMMMM(string) {
            let split = string.split('-');
            let year = Number(split[0]);
            let month = Number(split[1]);
            return year + ' ' + this.getMonthNameByNumber(month);
        },
        calculateProfit(matches, totalAmount, totalOdds) {
            let statuses = {
                WON: 'Nyert',
                LOST: 'Vesztett',
                RETURN: 'Tét visszajár'
            };

            let status = statuses.WON;
            let profit = (totalOdds - 1) * totalAmount;

            let wins = matches.filter(x => {
                return x.status.trim().toLowerCase() === statuses.WON.toLowerCase()
            });
            let loses = matches.filter(x => {
                return x.status.trim().toLowerCase() === statuses.LOST.toLowerCase()
            });
            let returns = matches.filter(x => {
                return x.status.trim().toLowerCase() === statuses.RETURN.toLowerCase()
            });

            if ((wins.length + loses.length + returns.length) !== matches.length) {
                return [];
            }

            if (loses.length > 0) {
                status = statuses.LOST;
                profit = (-1) * totalAmount;
            } else {
                if (returns.length > 0) {
                    if (wins.length > 0) {
                        let winAmount = this.roundDouble(this.sumNumberList(wins.map(x => {
                            return x.odds * totalAmount
                        })));
                        profit = this.roundDouble(winAmount - totalAmount);
                    } else {
                        status = statuses.RETURN;
                        profit = 0;
                    }
                }
            }

            return [status, this.roundDouble(profit)];
        },
        isPhoneNumberValid(value) {
            let regex = /^(0|[+]){0,1}[0-9]{7,10}/g;
            let result = value.match(regex);
            return result && result.length === 1;
        },
        isEmailValid(value) {
            let emailRegex = /[^\.\s@][^@\s]*[^\.\s@]@[^\.\s@]+\.[^\.\s@]+/g;
            let result = value.match(emailRegex);
            return result && result.length === 1;
        },
        createXLSX(title, subject, columns, data) {
            let wb = xlsx.utils.book_new();
            wb.Props = {
                Title: title,
                Subject: subject,
                Author: 'Sportfogadók Clubja',
                CreatedDate: new Date()
            };

            wb.SheetNames.push('Report');

            let ws_data = [];
            ws_data.push(columns);
            data.forEach((json) => {
                ws_data.push(Object.values(json));
            });

            wb.Sheets['Report'] = xlsx.utils.aoa_to_sheet(ws_data);
            return xlsx.write(wb, {bookType: 'xlsx', type: 'binary'});
        },
        s2ab(s) {
            let buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
            let view = new Uint8Array(buf);  //create uint8array as viewer
            for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet

            return buf;
        },
        saveFile(title, out) {
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            let blob = new Blob([this.s2ab(out)], {type: "application/octet-stream"}),
                url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = title + '.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
        },
    }
}