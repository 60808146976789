<template>
    <div class="admin-page">
        <div v-show="loading" class="loading-spinner">
            <b-spinner class="m-auto" variant="primary" label="Spinning"></b-spinner>
        </div>
        <parallax class="page-parallax-bg"></parallax>
        <b-row class="pt-5 main-content clear-filter" filter-color="admin-page-shadow" align-h="center">
                <b-card style="background-color: rgba(19,25,37,0.2);border-radius: 1rem;" class="mx-4">
                    <div class="h2 font-weight-bold mt-2 w-100 text-center text-primary">Admin panel</div>
                    <b-tabs pills card :vertical="!isMobile" active-nav-item-class="text-primary" nav-class="h5 text-uppercase">
                        <b-tab class="p-0" active>
                            <template v-slot:title>
                                <span class="mb-5 text-white">Személyek</span>
                            </template>
                            <Users></Users>
                        </b-tab>
                        <b-tab class="p-0">
                            <template v-slot:title>
                                <span class="mb-5 text-white">Bizonylatok</span>
                            </template>
                            <Receipts></Receipts>
                        </b-tab>
                        <b-tab class="p-0">
                            <template v-slot:title>
                                <span class="mb-5 text-white">Csomagbeállítások</span>
                            </template>
                            <BundleConfig></BundleConfig>
                        </b-tab>
                        <b-tab class="p-0">
                            <template v-slot:title>
                                <span class="mb-5 text-white">E-mail küldés</span>
                            </template>
                            <div class="text-center">
                                <router-link tag="button" class="btn btn-lg btn-primary" :to="{name: 'admin-mail'}">
                                    Ugrás az e-mail küldéshez
                                </router-link>
                            </div>
                        </b-tab>
                    </b-tabs>
                </b-card>
        </b-row>
    </div>
</template>

<script>
    import store from "../../plugins/store";
    import Users from "./Users";
    import Receipts from "./Receipts";
    import BundleConfig from "./BundleConfigs";

    export default {
        name: "Admin",
        components: {
            Users,
            Receipts,
            BundleConfig
        },
        computed: {
            isMobile() {
                return this.$root.isMobile;
            },
            currentUser() {
                return store.getters.user;
            },
            loading() {
                return store.getters.isLoading;
            }
        },
        mounted() {
            let self = this;
            let assertAdmin = () => {
                let isAdmin = false;
                if (self.currentUser && self.currentUser.userRole) {
                    isAdmin = self.currentUser.userRole.toUpperCase() === 'ADMIN';
                }

                if (!isAdmin) {
                    self.$router.replace({name: 'profile'});
                }
            };
            this.loginCheck('profile', null, assertAdmin())
        }
    }
</script>

<style scoped>
</style>