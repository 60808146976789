<template>
    <div class="admin-page">
        <div v-show="loading" class="loading-spinner">
            <b-spinner class="m-auto" variant="primary" label="Spinning"></b-spinner>
        </div>
        <parallax class="page-parallax-bg"></parallax>
        <b-row class="pt-5 main-content clear-filter" filter-color="admin-page-shadow" align-h="center">
            <b-card style="background-color: rgba(19,25,37,0.2);border-radius: 1rem;" class="mx-4">
                <b-row>
                    <b-col>
                        <router-link tag="button"
                                     class="float-left btn btn-primary btn-lg" :to="{name: 'admin-panel'}">
                            {{backBtnTxt}}
                        </router-link>
                    </b-col>
                </b-row>
                <div class="h2 font-weight-bold mt-2 w-100 text-center text-primary mb-1">{{user.name}}</div>
                <div class="h4 font-weight-bold w-100 text-center text-primary my-0">{{user.email}}</div>
                <div class="h4 font-weight-bold w-100 text-center text-primary mt-0">{{ user.userRole ? $t('userRole.' + user.userRole.toLowerCase()) : ''}}</div>
                <b-row align-h="center">
                    <b-col cols="12" md="8" xl="6">
                        <b-table small dark class="outline-0 text-center" :items="[user]" :fields="fields">
                            <template v-slot:cell(enabled)="data">
                                <b-form-checkbox switch v-model="data.item.enabled">{{data.item.enabled ? 'Igen' : 'Nem'}}</b-form-checkbox>
                            </template>
                            <template v-slot:cell(verified)="data">
                                <b-form-checkbox disabled switch v-model="data.item.verified">{{data.item.verified ? 'Igen' : 'Nem'}}</b-form-checkbox>
                            </template>
                            <template v-slot:cell(loggedIn)="data">
                                <b-form-checkbox switch v-model="data.item.loggedIn">{{data.item.loggedIn ? 'Igen' : 'Nem'}}</b-form-checkbox>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
                <b-row align-h="center">
                    <b-col cols="12" md="8">
                        <b-row>
                            <b-col class="h2 text-center" style="color: black !important;">
                                Aktív csomagok
                            </b-col>
                        </b-row>
                        <b-row v-for="bundle in user.activeBundles" :key="bundle.id" style="border-radius: 1.2rem;background-color: whitesmoke;" class="mt-2">
                            <b-col class="text-primary h4 my-2" style="align-items: flex-end;">
                                <b-form-group :label="$t('bundle.' + bundle.id.toLowerCase() + '.title')" label-cols="auto">
                                    <b-form-checkbox switch v-model="bundle.active"></b-form-checkbox>
                                </b-form-group>
                            </b-col>
                            <b-col v-show="bundle.active" align-self="center">
                                <b-row align-v="center">
                                    <b-col>
                                        <b-form-input style="border-color: black" type="date" v-model="bundle.endDateForm"
                                                      @change="onDateTimeChange(bundle)"></b-form-input>
                                    </b-col>
                                    <b-col>
                                        <b-form-input style="border-color: black" type="time" v-model="bundle.endTimeForm"
                                                      @change="onDateTimeChange(bundle)"></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row align-h="center" class="mt-3">
                            <b-col class="text-center">
                                <b-button variant="primary" class="btn-lg h5" @click="onSubmitButton">Mentés</b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row align-h="center" class="mt-5">
                    <b-col cols="12" md="8" style="background-color: rgba(255,255,255,0.7);border-radius: 1rem;">
                        <b-row>
                            <b-col class="h2 text-center mt-2 mb-1">
                                Számlázási adatok
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <InvoiceForm :form="user.invoiceData || {}" :editable="false"></InvoiceForm>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row align-h="center" class="mt-5">
                    <b-col cols="12" class="text-center">
                        <div class="h2 text-white">Bizonylatok</div>
                    </b-col>
                    <b-col cols="12" md="8">
                        <b-pagination v-show="receipts ? receipts.length > perPage : false" align="fill"
                                      v-model="currentPage"
                                      :total-rows="receipts.length"
                                      :per-page="perPage"
                                      aria-controls="receipts-table"
                        >
                        </b-pagination>
                        <b-table id="receipts-table" dark :items="receipts" :fields="receiptFields"
                                 :per-page="perPage" :current-page="currentPage">
                            <template v-slot:cell(actions)="data">
                                <b-button variant="primary" @click="data.toggleDetails()">
                                    {{ data.detailsShowing ? '&#x25B2;' : '&#x25BC;' }} Részletek
                                </b-button>
                            </template>
                            <template v-slot:row-details="data" class="outline-0">
                                <b-card class="m-0 outline-0" style="background-color: #00000044">
                                    <b-row align-h="center">
                                        <b-col cols="12" md="10" xl="8">
                                            <b-table dark :items="data.item.products" :fields="productFields"></b-table>
                                            <hr style="border-color: grey">
                                        </b-col>
                                    </b-row>
                                    <b-row class="outline-0" align-h="center">
                                        <b-col cols="12" md="10" xl="8" class="outline-0">
                                            <InvoiceForm class="outline-0" :form="data.item.form || {}" :editable="false"></InvoiceForm>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
            </b-card>
        </b-row>
    </div>
</template>

<script>
    import backend from "../../plugins/backend";
    import InvoiceForm from "../profile/InvoiceForm";
    import store from "../../plugins/store";

    export default {
        name: "UserPage",
        props: {
            userId: {
                Type: String
            }
        },
        components: {
            InvoiceForm
        },
        computed: {
            loading() {
                return store.getters.isLoading;
            }
        },
        data() {
            return {
                saveSuccess: false,
                saveFailed: false,
                currentPage: 1,
                perPage: 10,
                backBtnTxt: '<< Személyek',
                user: {},
                fields: [],
                receiptFields: [],
                receipts: [],
                productFields: []
            }
        },
        methods: {
            addInactiveBundles() {
                let self = this;
                ['premium', 'mustang'].forEach(bundleId => {
                    if (!self.user.activeBundles.find(x => x.id === bundleId)) {
                        self.$set(self.user.activeBundles, self.user.activeBundles.length, {id: bundleId, active: false})
                    }
                })
            },
            loadUserData() {
                let self = this;
                if(self.userId) {
                    backend.getUserById(self.userId).then(response => {
                        self.user = response.body;
                        self.user.activeBundles.forEach(bundle => {
                            bundle.active = true;
                            let date = Math.floor(new Date(bundle.endTime * 1000).getTime() / 1000);
                            bundle.endDateForm = self.printDateYYYYMMDD(date);
                            bundle.endTimeForm = self.printTimeHHMM(date);
                        });
                        self.addInactiveBundles();
                    }).catch(err => {
                    });
                }
            },
            loadReceiptsConnectedToUser() {
                let self = this;
                if(self.userId) {
                    backend.getReceiptsConnectedToUser(self.userId).then(response => {
                        self.receipts = response.body;
                        self.receipts.forEach(receipt => {
                            receipt.status = self.$t('receipt.status.' + receipt.status.toLowerCase())
                        })
                    }).catch(err => {
                    });
                }
            },
            initTableFields() {
                let self = this;
                self.fields = [
                    {
                        key: 'enabled',
                        label: 'Engedélyezve'
                    },
                    {
                        key: 'verified',
                        label: 'Email megerősítve'
                    },
                    {
                        key: 'loggedIn',
                        label: 'Bejelentkezve'
                    },
                    {
                        key: 'creationTime',
                        label: 'Regisztrált',
                        sortable: true,
                        formatter(value) {
                            return self.printDate((new Date(value)).getTime() / 1000);
                        }
                    },
                    {
                        key: 'lastSignIn',
                        label: 'Utolsó belépés',
                        sortable: true,
                        formatter(value) {
                            return self.printDate((new Date(value)).getTime() / 1000);
                        }
                    }
                ];
                self.receiptFields = [
                    {
                        key: 'orderId',
                        label: 'Azonosító',
                        sortable: true
                    },
                    {
                        key: 'status',
                        label: 'Státusz',
                        sortable: true
                    },
                    {
                        key: 'method',
                        label: 'Fizetési mód',
                        sortable: true
                    },
                    {
                        key: 'valid',
                        label: 'Érvényes',
                        sortable: true,
                        formatter: (value) => {
                            return value ? 'IGEN' : 'NEM'
                        }
                    },
                    {
                        key: 'actions',
                        label: 'Műveletek',
                        tdClass: 'p-0'
                    }
                ];
                self.productFields = [
                    {
                        key: 'description',
                        label: 'Tétel'
                    },
                    {
                        key: 'price',
                        label: 'Ár',
                        formatter: (value) => {
                            return self.formatNumberWithUnit(value, 'Ft')
                        }
                    }
                ]
            },
            onDateTimeChange(bundle) {
                if (bundle.endDateForm && bundle.endTimeForm) {
                    bundle.endTime = Math.floor(new Date(bundle.endDateForm + 'T' + bundle.endTimeForm).getTime() / 1000);
                }
            },
            onSubmitButton() {
                let self = this;
                self.saveSuccess = false;
                self.saveFailed = false;
                self.user.activeBundles = self.user.activeBundles.filter(x => {return x.endTime > 0;});
                backend.updateUser(self.userId, self.user).then(response => {
                    if (response.body.nModified > 0) {
                        self.saveSuccess = true;
                        self.loadUserData();
                    } else {
                        self.saveFailed = true;
                    }
                }).catch(err => {
                    self.saveFailed = true;
                });
            }
        },
        mounted() {
            this.loginCheck('index');
            this.loadUserData();
            this.initTableFields();
            this.loadReceiptsConnectedToUser()
        }
    }
</script>

<style>
    .form-row {
        align-items: flex-end;
    }
</style>