<template>
    <div :style="outerStyles">
        <div :style="innerStyles"></div>
    </div>
</template>

<script>
    export default {
        name: "HexaTile",
        props: {
            size: {
                type: Number,
                default: 8
            },
            pos: {
                type: Object,
                default: null
            },
            image: {
                type: String,
                default: '/img/hexa-pic-2.png'
            }
        },
        computed: {
            outerStyles() {
                let styles = [
                    {'width': this.size + 'rem'},
                    {'height': this.size + 'rem'},
                    {'position': 'absolute'}
                ];
                if (this.pos){
                    if (this.pos.top) {
                        let pos = {'top': this.pos.top + 'rem'};
                        styles.push(pos);
                    }
                    if (this.pos.left) {
                        let pos = {'left': this.pos.left + 'rem'};
                        styles.push(pos);
                    }
                    if (this.pos.right) {
                        let pos = {'right': this.pos.right + 'rem'};
                        styles.push(pos);
                    }
                    if (this.pos.bottom) {
                        let pos = {'bottom': this.pos.bottom + 'rem'};
                        styles.push(pos);
                    }
                }
                return styles;
            },
            innerStyles() {
                return [
                    {'background-image': 'url(' + this.image + ')'},
                    {'background-size': 'contain'},
                    {'background-position': 'center'},
                    {'background-repeat': 'no-repeat'},
                    {'width': '100%'},
                    {'height': '100%'},
                ];
            }
        }
    }
</script>

<style scoped>
</style>