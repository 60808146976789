import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/index/Index.vue';
import Login from './pages/login/Login.vue';
import Register from './pages/register/Register.vue';
import SCNavbar from './layout/SCNavbar.vue';
import StarterFooter from './layout/StarterFooter.vue';
import Introduction from './pages/introduction/Introduction.vue';
import Bundles from './pages/shop/Bundles.vue';
import Bundle from './pages/shop/Bundle.vue';
import Statistics from './pages/statistics/Statistics.vue';
import Profile from './pages/profile/Profile.vue';
import AuthAction from './pages/auth/AuthAction.vue';
import NeedHelp from './pages/needhelp/NeedHelp.vue';
import Checkout from './pages/shop/Checkout.vue';
import Cart from './pages/shop/Cart.vue';
import ASZF from './pages/index/ASZF';
import GDPR from './pages/index/GDPR';
import Admin from './pages/admin/Admin';
import BetPage from './pages/admin/BetPage';
import UserPage from './pages/admin/UserPage';
import Mail from './pages/mail/Mail';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            components: {
                default: Index,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                header: {colorOnScroll: 0},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/introduction',
            name: 'introduction',
            components: {
                default: Introduction,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                header: {colorOnScroll: 0},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/shop/bundles',
            name: 'bundles',
            components: {
                default: Bundles,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                header: {colorOnScroll: 0},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/shop/bundles/:id',
            name: 'bundle',
            components: {
                default: Bundle,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                default: true,
                header: {colorOnScroll: 0},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/statistics',
            name: 'statistics',
            components: {
                default: Statistics,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                header: {colorOnScroll: 0},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/login',
            name: 'login',
            components: {
                default: Login,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/register',
            name: 'register',
            components: {
                default: Register,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/help',
            name: 'need-help',
            components: {
                default: NeedHelp,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/profile',
            name: 'profile',
            components: {
                default: Profile,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                header: {colorOnScroll: 0},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/_auth/action',
            name: 'auth-action',
            components: {
                default: AuthAction,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                header: {colorOnScroll: 0},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/shop/checkout',
            name: 'shop-checkout',
            components: {
                default: Checkout,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                default: true,
                header: {colorOnScroll: 0},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/shop/order',
            name: 'shop-cart',
            components: {
                default: Cart,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                default: true,
                header: {colorOnScroll: 0},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/aszf',
            name: 'aszf',
            components: {
                default: ASZF,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                default: true,
                header: {colorOnScroll: 0},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/gdpr',
            name: 'gdpr',
            components: {
                default: GDPR,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                default: true,
                header: {colorOnScroll: 0},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/admin',
            name: 'admin-panel',
            components: {
                default: Admin,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                default: true,
                header: {colorOnScroll: 0},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/admin/user/:userId',
            name: 'admin-user-page',
            components: {
                default: UserPage,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                default: true,
                header: {colorOnScroll: 0},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/admin/bet/:betId',
            name: 'admin-bet-page',
            components: {
                default: BetPage,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                default: true,
                header: {colorOnScroll: 0},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/admin/mail',
            name: 'admin-mail',
            components: {
                default: Mail,
                header: SCNavbar,
                footer: StarterFooter
            },
            props: {
                default: true,
                header: {colorOnScroll: 0},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '*',
            redirect: '/'
        }
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return {selector: to.hash};
        } else {
            return {x: 0, y: 0};
        }
    }
});
