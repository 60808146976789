<template>
    <b-row class="bet-row" :class="[{'w-100': isMobile || onModal}]" align-v="center" align-h="center">
        <b-col cols="12" :xl="onModal ? 12 : 9" :class="{'px-0': onModal}">
            <b-card>
                <b-row>
                    <b-col>
                        <div v-if="template" class="float-left text-primary h3 font-weight-bold my-0">MINTA</div>
                        <router-link tag="button" v-if="editable && !template && userRole.toUpperCase() === 'ADMIN'"
                                     class="float-left btn btn-primary btn-lg" :to="{name: 'admin-bet-page', params: {'betId': bet.id || bet._id}}">
                            {{$t('common.edit')}}
                        </router-link>
                        <router-link tag="button" v-if="!opened && !template"
                                     class="float-left btn btn-primary btn-lg" :to="{name: 'bet-page', params: {'id': bet.id || bet._id}}">
                            {{$t('common.view')}}
                        </router-link>
                        <div class="float-right text-primary h5 font-weight-bold">{{ (bet.betType ? $t('tips.' + bet.betType) : '') + ' ' + $t('tips.tip_idea')}}</div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="10" sm="9" md="4" lg="4" class="mx-auto mb-2">
                        <div class="bet-logo"
                             :style="getLogoStyle()"></div>
                    </b-col>
                    <b-col cols="12" sm="12" md="8" lg="8" class="align-self-start mx-auto text-black">
                        <b-row class="h3 my-0">
                            <b-col cols="5" md="4" lg="3" xl="4">
                                <b>Időpont:</b>
                            </b-col>
                            <b-col :class="[{'text-right': isMobile}]" cols="7" md="8" lg="9"
                                   xl="8">
                                {{self.printDate(bet.targetDate)}}
                            </b-col>
                        </b-row>
                        <b-row class="h3 my-0">
                            <b-col cols="4" md="4" lg="3" xl="4">
                                <b>Tét:</b>
                            </b-col>
                            <b-col :class="[{'text-right': isMobile}]" cols="7" md="8" lg="9"
                                   xl="8">
                                {{bet.totalAmount + ' egység'}}
                            </b-col>
                        </b-row>
                        <b-row class="h3 my-0">
                            <b-col cols="4" md="4" lg="3" xl="4">
                                <b>Odds:</b>
                            </b-col>
                            <b-col :class="[{'text-right': isMobile}]" cols="7" md="8" lg="9"
                                   xl="8">
                                {{bet.totalOdds}}
                            </b-col>
                        </b-row>
                        <b-row class="h3 my-0" v-if="bet.status">
                            <b-col cols="4" md="4" lg="3" xl="4">
                                <b>Kimenetel:</b>
                            </b-col>
                            <b-col :class="[{'text-right': isMobile}]" cols="7" md="8" lg="9"
                                   xl="8">
                                {{bet.status}}
                            </b-col>
                        </b-row>
                        <b-row class="h3 my-0" v-if="bet.status && bet.status !== 'Még nincs eredmény'">
                            <b-col cols="4" md="4" lg="3" xl="4">
                                <b>Hozam:</b>
                            </b-col>
                            <b-col :class="[{'text-right': isMobile}]" cols="7" md="8" lg="9"
                                   xl="8">
                                {{bet.profit + ' egység'}}
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row v-if="bet.matches">
                    <b-col cols="12" class="my-2">
                        <div class="h3 my-0 font-weight-bold text-black">{{bet.matches.length > 1 ?
                            'Mérkőzések:' : 'Mérkőzés:'}}
                        </div>
                    </b-col>
                    <b-col cols="12" :class="{'px-0': isMobile || onModal}" class="overflow-auto">
                        <b-table tbody-class="h6 my-table" dark :items="bet.matches"
                                 :fields="matchFields">

                        </b-table>
                    </b-col>
                </b-row>
                <b-row v-if="bet.paragraphs && bet.paragraphs.length > 0">
                    <b-col cols="7" md="9" class="my-2">
                        <div class="h3 my-0 font-weight-bold text-black">Leírás:</div>
                    </b-col>
                    <b-col cols="5" md="3">
                        <b-button variant="primary" class="w-100 h5" @click="detailsShowing = !detailsShowing">{{detailsShowing ? 'Elrejt' : 'Mutat'}}</b-button>
                    </b-col>
                    <b-col v-show="detailsShowing" cols="12">
                        <div class="text-justify h5 font-weight-bold text-black"
                             v-for="paragraph in bet.paragraphs" :key="paragraph">
                            <p class="m-0" style="line-height: normal" v-for="line in paragraph.split('\n')" :key="line">
                                {{line}}
                            </p>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    import store from "../../plugins/store";

    export default {
        name: "BetRow",
        props: {
            bet: {
                type: Object
            },
            template: {
                type: Boolean,
                default: false
            },
            editable: {
                type: Boolean,
                default: false
            },
            onModal: {
                type: Boolean,
                default: false
            },
            opened: {
              type: Boolean,
              default: false
            }
        },
        data() {
            return {
                detailsShowing: false,
                matchFields: [
                    {
                        key: 'home',
                        label: 'Hazai'
                    },
                    {
                        key: 'guest',
                        label: 'Vendég'
                    },
                    {
                        key: 'option',
                        label: 'Opció'
                    },
                    {
                        key: 'odds',
                        label: 'Odds'
                    }
                ]
            }
        },
        computed: {
            self() {
                return this;
            },
            isMobile() {
                return this.$root.isMobile;
            },
            userRole() {
                if (!store.getters.user) return  'guest';
                return store.getters.user.userRole;
            }
        },
        methods: {
            getLogoStyle() {
                let imgSrc = this.bet.img;
                if (imgSrc) {
                    if (!imgSrc.startsWith('http')) imgSrc = '/img/' + imgSrc;
                } else {
                    imgSrc = '/img/bundle_big.jpg';
                }

                return [
                    {'background-image': 'url(' + imgSrc + ')'}
                ]
            },
            initRowVariants() {
                if (this.bet.matches){
                    this.bet.matches.forEach(match => {
                        if (match.status) {
                            if (match.status === 'Nyert') match._rowVariant = 'success';
                            else if (match.status === 'Vesztett') match._rowVariant = 'danger';
                            else if (match.status === 'Tét visszajár') match._rowVariant = 'info';
                            else match._rowVariant = 'default';
                        }
                    });
                }
            }
        },
        mounted() {
            this.initRowVariants();
        },
        watch: {
            bet() {
                this.initRowVariants();
            }
        }
    }
</script>

<style>
    .bet-logo {
        text-align: center;
        margin: auto;
        width: 100%;
        height: 180px;
        overflow: hidden;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 0.25rem;
    }
</style>