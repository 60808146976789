<template>
    <div class="login-page">
        <div class="page-body">
            <div v-show="loading" class="loading-spinner">
                <b-spinner class="m-auto" variant="primary" label="Spinning"></b-spinner>
            </div>
            <parallax class="page-parallax-bg" :style="'background-image: url(' + bgSrc + ');'"></parallax>
            <div class="container pt-1" v-if="errors || info">
                <alert v-for="error in errors" :key="error" class="my-1" type="danger" dismissible @dismissed="onDismissAlert(error)">
                    <div class="float-left mt-1 mr-2">
                        <i class="fa fa-exclamation"></i>
                    </div>
                    <strong>{{$t('messages.error')}}!</strong> {{$t(error)}}
                </alert>
                <alert v-for="item in info" :key="item" class="m-0" type="info" dismissible @dismissed="onDismissInfo(item)">
                    <div class="float-left mt-1 mr-2">
                        <i class="fa fa-exclamation"></i>
                    </div>
                    <span>{{$t(item)}}</span>
                </alert>
            </div>

            <div class="mt-0">
                <div class="container pt-0">
                    <div class="col-md-9 col-lg-6 col-xl-5 mx-auto">
                        <div class="logo-container" :style="'background-image: url(' + logo + ')'"></div>

                        <b-input-group>
                            <b-input-group-prepend>
                                <span class="input-group-text"><i class="fa fa-user"></i></span>
                            </b-input-group-prepend>
                            <b-form-input v-model="name" size="lg" :placeholder="$t('register.name')" @keypress.enter="onRegisterBtn"></b-form-input>
                        </b-input-group>
                        <b-input-group>
                            <b-input-group-prepend>
                                <span class="input-group-text"><i class="fa fa-at"></i></span>
                            </b-input-group-prepend>
                            <b-form-input type="email" v-model="email" size="lg" :placeholder="$t('register.email')" @keypress.enter="onRegisterBtn"></b-form-input>
                        </b-input-group>
                        <b-input-group>
                            <b-input-group-prepend>
                                <span class="input-group-text"><i class="fa fa-key"></i></span>
                            </b-input-group-prepend>
                            <b-form-input type="password" v-model="pass" size="lg" :placeholder="$t('register.password')" @keypress.enter="onRegisterBtn"></b-form-input>
                        </b-input-group>
                        <b-input-group>
                            <b-input-group-prepend>
                                <span class="input-group-text"><i class="fa fa-key"></i></span>
                            </b-input-group-prepend>
                            <b-form-input type="password" v-model="passAgain" size="lg" :placeholder="$t('register.passwordAgain')" @keypress.enter="onRegisterBtn"></b-form-input>
                        </b-input-group>

                        <b-form-checkbox v-model="aszfCheck" class="mt-4">
                            Megismertem és elfogadom az '<a target="_blank" href="/aszf">Általános Szerződési Feltételek</a>'-et,
                            valamint az '<a target="_blank" href="/gdpr">Adatvédelmi szabályzat</a>'-ot.
                        </b-form-checkbox>
                        <b-button variant="primary" class="mt-1 mb-3 btn-round btn-lg w-100" v-on:click="onRegisterBtn">{{$t('register.registerbtn')}}</b-button>
                        <b-row class="mb-5">
                            <b-col><h6><router-link tag="a" :to="{name: 'login'}" class="float-left link footer-link">{{$t('register.login')}}</router-link></h6></b-col>
                            <b-col><h6><router-link tag="a" :to="{name: 'need-help'}" class="float-right link footer-link">{{$t('login.needhelp')}}</router-link></h6></b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {Alert} from '@/components';
    import backend from "../../plugins/backend";
    import store from "../../plugins/store";

    export default {
        name: 'register',
        components: {
            Alert
        },
        data() {
            return {
                bgSrc: '/img/plain_black.jpg',
                logo: '/img/bet-logo-t-blue.png',
                aszfCheck: false,
                email: '',
                pass: '',
                passAgain: '',
                name: '',
                emailError: true,
                errors: [],
                info: [],
                emailRegex: /[^\.\s@][^@\s]*[^\.\s@]@[^\.\s@]+\.[^\.\s@]+/g,
                passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%_*?&\.]{8,32}$/g
            }
        },
        computed: {
            loading() {
                return store.getters.isLoading;
            }
        },
        methods: {
            onDismissAlert(e) {
                this.errors = this.errors.filter(element => element !== e);
            },
            onDismissInfo(e) {
                this.info = this.info.filter(element => element !== e);
            },
            validateEmail(value) {
                if (value === '') this.errors.push('messages.email_empty');
                else {
                    if (value.startsWith('.')) this.errors.push('messages.email_invalid');
                    else if ((value.match(/@/g) || []).length !== 1) this.errors.push('messages.email_invalid');
                    else if ((value.match(this.emailRegex) || []).length !== 1) this.errors.push('messages.email_invalid');
                    else return true
                }
                return false
            },
            validatePassword(value, value2) {
                if (value !== value2) {
                    this.errors.push('messages.password_doesnt_match');
                    return false
                } else if (value === '') {
                    this.errors.push('messages.password_empty');
                    return false
                }
                else if (value.length < 8 || value.length > 32){
                    this.errors.push('messages.password_invalid');
                    return false;
                }
                else if ((value.match(this.passwordRegex) || []).length !== 1) {
                    this.errors.push('messages.password_invalid');
                    return false;
                }
                return true;
            },
            validateAszfCheck() {
                if (!this.aszfCheck) {
                    this.errors.push('messages.aszf_error')
                }
                return this.aszfCheck;
            },
            onRegisterBtn() {
                let email = this.email.slice();
                let pass = this.pass.slice();
                let passAgain = this.passAgain.slice();
                this.errors = [];
                this.info = [];
                let aszfChecked = this.validateAszfCheck();
                let validEmail = this.validateEmail(email);
                let validPassword = this.validatePassword(pass, passAgain);
                if (aszfChecked && validEmail && validPassword && this.errors.length === 0) {
                    this.onRegister(email, pass);
                }
            },
            onRegister() {
                backend.registerWithEmailPassword(this.email, this.pass, this.name).then(response => {
                    this.pass = '';
                    this.passAgain = '';
                    if (!response.success){
                        this.errors = response.errors;
                    } else {
                        if (response.body) {
                            this.info.push('messages.register_success');
                        } else {
                            this.errors.push('messages.unexpected_error')
                        }
                    }
                }).catch(() => {
                    this.errors.push('messages.unexpected_error');
                });
            }
        },
        mounted() {
            this.alreadyLoggedInCheck('profile');
            this.info = [];
            this.errors = [];
        }
    };
</script>
<style>
</style>
