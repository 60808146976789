<template>
    <b-row style="width: 80%; max-width: 1400px; margin: auto;">
        <b-col>
            <b-card>
                <div class="h3">
                    {{ 'Tárgy: [' + mail.tag + '] ' + mail.subject }}
                </div>
                <div :style="mail.greetingStyle">
                    <div>{{ mail.greeting + (mail.withName ? ' NÉV!' : ' Sportfogadó!') }}</div>
                    <div>{{ mail.greetingExtra }}</div>
                </div>
                <div :style="getStyle(paragraph.style)"
                     v-for="(paragraph, index) in mail.paragraphs" :key="index">
                    <div class="m-0" style="line-height: normal" v-for="line in paragraph.text.split('\n')" :key="line">
                        {{ line }}
                    </div>
                </div>
                <div v-if="mail.farewell" :style="mail.farewellStyle">
                    <div class="m-0" style="line-height: normal" v-for="line in mail.farewell.split('\n')" :key="line">
                        {{ line }}
                    </div>
                </div>
                <div v-if="mail.footer" :style="mail.footerStyle">
                    <div class="m-0" style="line-height: normal" v-for="line in mail.footer.split('\n')" :key="line">
                        {{ line }}
                    </div>
                    <div :style="getImgStyle(mail.footerImgStyle, mail.footerImg)"></div>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "MailPreview",
    props: {
        mail: {
            type: Object
        }
    },
    methods: {
        getStyle(styleObject) {
            let styleStr = '';
            for (const prop in styleObject) {
                if (styleObject.hasOwnProperty(prop)) {
                    styleStr += prop + ':' + styleObject[prop] + ';'
                }
            }

            return styleStr
        },
        getImgStyle(style, img) {
            let styleObject = style;
            let imgUrl = 'https://www.sportfogadokclubja.hu/img/' + img
            if(img.startsWith('http')) {
                imgUrl = img
            }
            styleObject['background-image'] = 'url(' + imgUrl + ')'
            return this.getStyle(styleObject);
        }
    }
}
</script>

<style scoped>

</style>