<template>
    <div>
        <div class="statistics-body">
            <div v-show="loading" class="loading-spinner">
                <b-spinner class="m-auto" variant="primary" label="Spinning"></b-spinner>
            </div>
            <parallax-page-header class="clear-filter" filter-color="statistic-cover-shadow" :src="heroSrc"
                                  :title="title" :social-media="socialMedia" :text="text" :vertical-position="'40%'"/>
            <div v-show="isMobile">
                <HexaTiles style="position: absolute; top: 12rem; left: 0;z-index: -5;" :count="20" :size="9"
                           :group-count="5"
                           :min-left="1" :max-left="5"></HexaTiles>
            </div>
            <div v-show="!isMobile">
                <HexaTiles style="position: absolute; top: 12rem; left: 0;z-index: -5;" :count="10" :size="14"
                           :group-count="4"
                           :min-left="0" :max-left="1"></HexaTiles>
                <HexaTiles style="position: absolute; top: 12rem; right: 0;z-index: -5;" :count="10" :size="14"
                           :group-count="4"
                           :min-left="-3" :max-left="-1"></HexaTiles>
            </div>
            <parallax class="page-parallax-bg"></parallax>
            <b-row class="items-container mx-auto clear-filter" filter-color="statistic-page-shadow">
                <div class="background-hack clear-filter" filter-color="statistic-hack-shadow"></div>
                <b-col>
                    <b-row>
                        <b-col>
                            <b-row align-h="center" class="mx-0 p-0 mb-5">
                                <b-col v-for="tab in tabs" :key="tab.key" cols="4" lg="3" class="px-0">
                                    <div class="my-menu-tab-holder" :class="{'m-my-menu-tab-holder': isMobile}">
                                        <div @click="changeBetType(tab.key)" class="my-menu-tab"
                                             :class="[{'my-menu-tab-active': betType === tab.key}, {'m-my-menu-tab': isMobile}]">
                                            {{tab.title}}
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row v-if="totalProfitList.length > 0" style="width: 90%" class="mx-auto"
                                   :class="{'w-100': isMobile}">
                                <b-col cols="12" class="text-center">
                                    <div class="text-center">
                                        <div class="h2 text-golden font-weight-bold" style="text-shadow: black 0 0 0.4rem;">
                                            Hozam tényleges változása
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12">
                                    <b-card style="background-image: linear-gradient(0deg, rgba(25,37,55, 0.8), rgba(25,37,55, 0.94);background-color: transparent;"
                                            class="p-0">
                                        <line-chart :chart-data="lineChartData"
                                                    :options="lineChartOptions"></line-chart>
                                    </b-card>
                                </b-col>
                            </b-row>
                            <b-row v-else style="width: 90%" class="mx-auto"
                                   :class="{'w-100': isMobile}">
                                <b-col cols="12" class="text-center">
                                    <div class="text-center text-golden font-weight-bold h2" style="text-shadow: black 0 0 0.4rem;">
                                        Nincs megjeleníthető statisztika
                                    </div>
                                    <div class="text-primary h3 font-weight-bold">
                                        {{getSubtitle()}}
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row v-if="sumTableData.length > 0" style="width: 90%" class="mx-auto mb-5 mt-4" :class="{'w-100': isMobile}">
                                <b-col class="overflow-auto p-0 text-center">
                                    <b-row class="p-0 m-0" align-h="center">
                                        <b-col class="p-0" cols="12" lg="8">
                                            <b-table class="h5" dark :items="sumTableData" :fields="sumTableFields" sort-by="dateStr"
                                                     :tbody-tr-class="sumDataRowClass"></b-table>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>

                            <b-row v-if="isAdmin" class="text-primary h4 font-weight-bold" align-h="center">
                              <b-col class="overflow-auto text-center card rounded" cols="12" lg="10" xl="6">
                                <b-row align-h="center">
                                  <b-col cols="12">
                                    Statisztika felvétele (Admin)
                                  </b-col>
                                </b-row>
                                <b-row align-h="center">
                                  <b-col>
                                    <label>Hónap:</label>
                                  </b-col>
                                  <b-col>
                                    <b-form-input style="border-color: black" type="month" v-model="inputDate"></b-form-input>
                                  </b-col>
                                  <b-col><label>Profit:</label></b-col>
                                  <b-col><b-form-input style="border-color: black" type="number" v-model="inputProfit"></b-form-input></b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <b-button variant="primary" class="btn-lg" :disabled="!inputValid" @click="postStatistic">Felvétel/frissítés</b-button>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import ParallaxPageHeader from "../components/ParallaxPageHeader";
    import backend from "../../plugins/backend";
    import LineChart from "../components/LineChart";
    import HexaTiles from "../components/HexaTiles";
    import store from "../../plugins/store";
    import helper from "../../plugins/helper";

    export default {
        name: "statistics",
        components: {
            ParallaxPageHeader,
            LineChart,
            HexaTiles
        },
        computed: {
            self() {
                return this;
            },
            isMobile() {
                return this.$root.isMobile;
            },
            loading() {
                return store.getters.isLoading;
            },
            currentUser() {
              return store.getters.user;
            },
            isAdmin() {
              let isAdmin = false;
              if (this.currentUser && this.currentUser.userRole) {
                isAdmin = this.currentUser.userRole.toUpperCase() === 'ADMIN';
              }
              return isAdmin;
            },
          inputValid() {
              return this.inputDate && this.inputProfit;
          }
        },
        data() {
            return {
                heroSrc: '/img/hero-statistics.jpg',
                title: 'statistics.title',
                text: 'statistics.subtitle',
                socialMedia: false,
                showTable: false,
                interval: {
                    start: '',
                    end: ''
                },
                betType: 'premium',
                sumTableData: [],
                sumTableFields: [],
                data: [],
                fields: [],
                detailsFields: [],
                lineChartData: {},
                lineChartOptions: {},
                monthList: [],
                profitList: [],
                totalProfitList: [],
                tabs: [
                    {
                        key: 'premium',
                        title: 'Prémium'
                    }
                ],
              inputDate: null,
              inputProfit: null
            };
        },
        methods: {
            getSubtitle() {
                let self = this;
                let tab = self.tabs.find(x => x.key === self.betType);
                if (tab) {
                    return tab.subtitle
                } else {
                    return ""
                }
            },
            changeBetType(type) {
                let self = this;
                self.betType = type;
            },
            fillData() {
                let self = this;
                self.totalProfitList = [];
                if (self.profitList.length <= 1) return;
                self.profitList.forEach((profit, index) => {
                    if (index > 0) {
                        let totalProfit = profit + self.totalProfitList[index - 1];
                        self.totalProfitList.push(self.roundDouble(totalProfit));
                    } else {
                        self.totalProfitList.push(self.roundDouble(profit));
                    }
                });

                this.lineChartData = {
                    labels: self.monthList,
                    datasets: [
                        {
                            label: 'Teljes Hozam',
                            borderColor: 'goldenrod',
                            backgroundColor: 'goldenrod',
                            fill: false,
                            pointRadius: 3.5,
                            data: self.totalProfitList,
                            cubicInterpolationMode: 'monotone'
                        },
                        {
                            label: 'Havi Hozam',
                            borderColor: '#192537',
                            backgroundColor: '#192537',
                            showLine: false,
                            fill: false,
                            pointRadius: 0,
                            data: self.totalProfitList,
                            cubicInterpolationMode: 'monotone'
                        }
                    ]
                };
                this.lineChartOptions = {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                fontColor: 'goldenrod',
                                stepSize: 10,
                                fontSize: 14,
                                fontFamily: 'futura-pt',
                                fontStyle: 'bold'
                            },
                            gridLines: {
                                display: true,
                                color: 'rgba(208, 168, 32, 0.1)'
                            },
                            scaleLabel: {
                                display: true,
                                fontColor: 'goldenrod',
                                fontSize: 24,
                                fontFamily: 'futura-pt',
                                fontStyle: 'bold',
                                labelString: 'Hozam (egység)'
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                fontColor: 'goldenrod',
                                fontSize: 14,
                                fontFamily: 'futura-pt',
                                fontStyle: 'bold',
                                callback: function(value, index, values) {
                                    return self.printYYYYMMtoYYYYMMMM(value);
                                }
                            },
                            gridLines: {
                                display: false
                            },
                            scaleLabel: {
                                display: true,
                                fontColor: 'goldenrod',
                                fontSize: 24,
                                fontFamily: 'futura-pt',
                                fontStyle: 'bold',
                                labelString: 'Idő (hónapok)'
                            }
                        }]
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        intersect: false,
                        mode: 'index',
                        xPadding: 50,
                        yPadding: 10,
                        caretPadding: 10,
                        callbacks: {
                            label: function (tooltipItem, data) {
                                let dsi = tooltipItem.datasetIndex;
                                let ds = data.datasets[dsi];

                                if (ds.label === 'Teljes Hozam') {
                                    let total = tooltipItem.value;
                                    if (total >= 0) total = '+' + total;
                                    return ds.label + ': ' + total;
                                } else if (ds.label === 'Havi Hozam') {
                                    let delta = self.profitList[tooltipItem.index];
                                    if (delta >= 0) delta = '+' + delta;

                                    return ds.label + ': ' + delta;
                                }
                            },
                            title: function (tooltipItems, data) {
                                let str = self.printYYYYMMtoYYYYMMMM(tooltipItems[0].xLabel);
                                if (tooltipItems[0].xLabel === self.monthList[0]) str += ' (indulás)';
                                // else if (tooltipItems[0].xLabel === self.monthList[self.monthList.length - 1]) str += ' (mostani)';
                                // else if (self.oddsList[tooltipItems[0].index] === 0) str += ' (szünet)';
                                return str;
                            },
                        }
                    },
                    stacked: false,
                    responsive: true,
                    maintainAspectRatio: false
                }
            },
            sumDataRowClass(item, type) {
                if (item.interval === 'Összesen') return 'tr-border-sum';
            },
            initSumDataTable(list) {
                let self = this;
                self.sumTableData = list.map(item => {
                    return {
                        dateStr: item.year + ' ' + item.monthNum,
                        interval: item.year + ' ' + this.getMonthNameByNumber(item.monthNum),
                        profit: item.profit
                    }
                });

                if (list.length > 0) {
                    let sumProfit = self.sumNumberList(list.map(item => {return item.profit}));

                    self.sumTableData.push(
                        {
                            dateStr: 'Z',
                            interval: 'Összesen',
                            profit: self.roundDouble(sumProfit, 2)
                        }
                    );

                    self.sumTableFields = [
                        {
                            key: 'interval',
                            label: 'Időszak'
                        },
                        {
                            key: 'profit',
                            label: 'Hozam',
                            formatter: value => {
                                let prefix = '+';
                                if (value < 0) prefix = '';
                                return prefix + value + ' egység';
                            },
                            tdClass: value => {
                                if (value > 0) {
                                    return 'text-success'
                                } else if (value < 0) {
                                    return 'text-danger'
                                }
                            }
                        }
                    ];
                }
            },
            initGraphData() {
                let self = this;
                self.monthList = [];
                self.profitList = [];

                backend.getStatisticByType(self.betType).then(response => {
                    if (response.body && response.body.length > 0) {
                        let list = response.body;

                        let monthNums = list.map(x => {
                            let monthNum = x.monthNum;
                            if (monthNum < 10) monthNum = '0' + monthNum;
                            return x.year + '-' + monthNum;
                        }).sort();

                        let minMonth = this.getPreviousMonthOfYYYYMM(monthNums[0]);

                        let currentMonth = new Date().getMonth() + 1;

                        if (currentMonth < 10) currentMonth = '0' + currentMonth;
                        let maxMonth = new Date().getUTCFullYear() + '-' + currentMonth;
                        if (Number(currentMonth) === 0) {
                            maxMonth = (new Date().getUTCFullYear() - 1) + '-12';
                        }

                        let inserted = [];
                        for (let monthNum = minMonth; monthNum < maxMonth; monthNum = this.getNextMonthOfYYYYMM(monthNum)) {
                            let stat = list.find(x => {
                                let xMonthNum = x.monthNum;
                                if (xMonthNum < 10) xMonthNum = '0' + xMonthNum;
                                return x.year + '-' + xMonthNum === monthNum
                            });
                            self.monthList.push(monthNum);
                            if (stat) {
                                inserted.push(stat);
                                self.profitList.push(stat.profit);
                            } else {
                                self.profitList.push(0);
                            }
                        }

                        self.fillData();
                        self.initSumDataTable(inserted);
                    } else {
                        self.monthList = [];
                        self.profitList = [];
                        self.fillData();
                        self.initSumDataTable([]);
                    }
                }).catch(err => {
                })
            },
            postStatistic() {
              let self = this;
              let type = self.betType;
              if (self.inputValid){
                let yearMonth = self.inputDate.split("-");
                let year = yearMonth[0];
                let month = yearMonth[1];
                let profit = self.inputProfit;
                backend.postStatistic(type, year, month, profit).then(response => {
                  setTimeout(()=> {
                    self.initGraphData();
                  }, 500);
                }).catch(err => {
                });
              }
            }
        },
        mounted() {
            this.initGraphData();
            let currentYear = new Date().getFullYear();
            let currentMonth = new Date().getMonth() + 1;
            if (currentMonth < 10) currentMonth = '0' + currentMonth;
            if (currentMonth > 12) currentMonth = '01'
            this.inputDate = currentYear + "-" + currentMonth;
            this.inputProfit = 1;
        },
        watch: {
            betType() {
                this.initGraphData();
            }
        }
    }
</script>

<style>
</style>