<template>
    <div class="page-checkout">
        <div v-show="loading" class="loading-spinner">
            <b-spinner class="m-auto" variant="primary" label="Spinning"></b-spinner>
        </div>
        <parallax class="page-parallax-bg"></parallax>
        <b-row class="main-container clear-filter" filter-color="checkout-page-shadow">
            <b-col class="secondary-container">
                <b-row class="checkout-container">
                    <b-col class="checkout-secondary-container">
                        <b-row class="checkout-title mb-4">
                            <b-col>
                                <div>Rendelés</div>
                            </b-col>
                        </b-row>
                        <b-row class="checkout-products" align-h="center">
                            <b-col cols="12" xl="10" class="products-container">
                                <div v-if="isMobile">
                                    <b-row>
                                        <hr style="width: 90%;">
                                        <b-col>
                                            <b-row align-h="center">
                                                <b-col cols="4" align-self="center">
                                                    <div class="mx-auto font-weight-bold" style="width: fit-content;">
                                                        Cikk
                                                    </div>
                                                </b-col>
                                                <b-col cols="8"><img class="table-item-image" :src="product.img"/>
                                                </b-col>
                                            </b-row>
                                            <hr style="width: 80%">
                                            <b-row class="my-3" align-h="center">
                                                <b-col cols="4" align-self="center">
                                                    <div class="mx-auto font-weight-bold" style="width: fit-content;">
                                                        Név
                                                    </div>
                                                </b-col>
                                                <b-col cols="8">
                                                    <div>{{product.description}}</div>
                                                </b-col>
                                            </b-row>
                                            <hr style="width: 80%">
                                            <b-row class="my-3" align-h="center">
                                                <b-col cols="4" align-self="center">
                                                    <div class="mx-auto font-weight-bold" style="width: fit-content;">
                                                        Darab
                                                    </div>
                                                </b-col>
                                                <b-col cols="8">
                                                    <div>{{product.count}}</div>
                                                </b-col>
                                            </b-row>
                                            <hr style="width: 80%">
                                            <b-row class="my-3" align-h="center">
                                                <b-col cols="4" align-self="center">
                                                    <div class="mx-auto font-weight-bold" style="width: fit-content;">
                                                        Egységár
                                                    </div>
                                                </b-col>
                                                <b-col cols="8">
                                                    <div>{{self.formatNumberWithUnit(product.price, self.currency, false, 0)}}</div>
                                                </b-col>
                                            </b-row>
                                            <hr style="width: 80%">
                                            <b-row class="my-3" align-h="center">
                                                <b-col cols="4" align-self="center">
                                                    <div class="mx-auto font-weight-bold" style="width: fit-content;">
                                                        Összesen
                                                    </div>
                                                </b-col>
                                                <b-col cols="8">
                                                    <div>{{self.formatNumberWithUnit(product.price * product.count, self.currency, false, 0)}}
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </div>
                                <b-table v-else :items="[product]" :fields="fields" tbody-tr-class="my-class">
                                    <template v-slot:cell(item)="data">
                                        <img class="table-item-image" :src="data.item.img"/>
                                    </template>
                                    <template v-slot:cell(total)="data">
                                        {{self.formatNumberWithUnit(data.item.price * data.item.count, self.currency, false, 0)}}
                                    </template>
                                </b-table>
                                <hr style="width: 90%">
                            </b-col>
                        </b-row>
                        <b-row align-h="center" class="mb-2">
                            <b-col cols="12" xl="10">
                                <b-row align-h="end">
                                    <b-col md="4" lg="3" align-v="center">
                                        <b-form-group label-class="text-left">
                                            <b-form-input v-model="coupon" :state="null" :placeholder="$t('form.coupon')" @change="initCart"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="3" lg="4">
                                        <b-tabs class="ml-lg-4 ml-md-2" pills>
                                            <b-tab v-for="option in options" :key="option.optionId"
                                                   @click="selectOption(option.optionId)">
                                                <template #title>
                                                    <div>{{option.days + ' NAP'}}</div>
                                                </template>
                                            </b-tab>
                                        </b-tabs>
                                    </b-col>
                                    <b-col md="5" align-v="center">
                                        <router-link tag="button" class="mb-4 btn btn-primary bundle-description text-center w-100" style="font-size: 1.2em;"
                                                     :to="{name: 'shop-checkout'}">
                                            Tovább a fizetéshez
                                        </router-link>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import store from "../../plugins/store";
    import backend from "../../plugins/backend";

    export default {
        name: "Cart",
        props: {
            productId: {
                type: String
            },
            optionId: {
                type: Number,
                default: 1
            },
            type: {
                type: String
            },
            months: {
                type: Number,
                default: 1
            }
        },
        data() {
            return {
                currency: 'HUF',
                product: {},
                coupon: '',
                options: [],
                selectedOptionId: 1,
                fields: [
                    {
                        key: 'item',
                        label: 'Cikk',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'description',
                        label: 'Név',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'count',
                        label: 'Darab',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'price',
                        label: 'Egységár',
                        formatter: value => {
                            return this.formatNumberWithUnit(value, this.currency, false, 0);
                        },
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'total',
                        label: 'Összesen',
                        tdClass: 'align-middle'
                    }
                ]
            }
        },
        computed: {
            isMobile() {
                return this.$root.isMobile;
            },
            self() {
                return this;
            },
            loading() {
                return store.getters.isLoading;
            }
        },
        methods: {
            initProduct() {
                let productData = store.getters.getCart;
                if (!productData.type || !productData.id || !productData.optionId) {
                    this.$router.replace({name: 'bundles'});
                }
                backend.getProduct(productData.type, productData.id, productData.optionId, productData.coupon).then(response => {
                    this.product = response.body;
                    this.product.type = productData.type;
                    if (!this.product.img.startsWith('http')) {
                      this.product.img = '/img/' + this.product.img;
                    }
                }).catch(err => {
                    this.$router.replace({name: 'bundles'});
                });
            },
            initCart() {
                let productData = {
                    type: this.type,
                    id: this.productId,
                    optionId: this.selectedOptionId,
                    coupon: this.coupon
                };
                store.commit('addToCart', productData);
                this.initProduct();
            },
            initOptions() {
                let productData = store.getters.getCart;
                if (!productData.type || !productData.id || !productData.optionId) {
                    this.$router.replace({name: 'bundles'});
                }

                backend.getProductOptions(productData.type, productData.id).then(response => {
                    this.options = response.body;
                }).catch(err => {
                    this.$router.replace({name: 'bundles'});
                });
            },
            selectOption(optionId) {
                let self = this;
                if (self.selectedOptionId === optionId) return;
                self.selectedOptionId = optionId;
                self.initCart();
            }
        },
        mounted() {
            if (this.productId && this.type && this.optionId) {
                this.selectedOptionId = this.optionId;
                this.initCart();
                this.initOptions();
            } else {
                this.coupon = store.getters.getCart.coupon;
                this.initProduct();
                this.initOptions();
            }
        }
    }
</script>

<style scoped>

</style>