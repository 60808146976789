<template>
    <div class="login-page">
        <div class="page-body">
            <div v-show="loading" class="loading-spinner">
                <b-spinner class="m-auto" variant="primary" label="Spinning"></b-spinner>
            </div>
            <parallax class="page-parallax-bg" :style="'background-image: url(' + bgSrc + ');'"></parallax>
            <div class="container pt-1" v-if="errors">
                <alert v-for="error in errors" :key="error" class="my-1" type="danger" dismissible @dismissed="onDismissAlert(error)">
                    <div class="float-left mt-1 mr-2">
                        <i class="fa fa-exclamation"></i>
                    </div>
                    <strong>{{$t('messages.error')}}!</strong> {{$t(error)}}
                </alert>
            </div>

            <div class="mt-0">
                <div class="container pt-0">
                    <div class="col-md-9 col-lg-6 col-xl-5 mx-auto">
                        <div class="logo-container" :style="'background-image: url(' + logo + ')'"></div>

                        <b-input-group>
                            <b-input-group-prepend>
                                <span class="input-group-text"><i class="fa fa-at"></i></span>
                            </b-input-group-prepend>
                            <b-form-input type="email" v-model="email" size="lg" :placeholder="$t('login.email')" @keypress.enter="onLoginBtn"></b-form-input>
                        </b-input-group>
                        <b-input-group>
                            <b-input-group-prepend>
                                <span class="input-group-text"><i class="fa fa-key"></i></span>
                            </b-input-group-prepend>
                            <b-form-input type="password" v-model="pass" size="lg" :placeholder="$t('login.password')" @keypress.enter="onLoginBtn"></b-form-input>
                        </b-input-group>

                        <b-button variant="primary" class="mt-5 mb-3 btn-round btn-lg w-100" v-on:click="onLoginBtn">{{$t('login.loginbtn')}}</b-button>
                        <b-row>
                            <b-col><h6><router-link tag="a" :to="{name: 'register'}" class="float-left link footer-link">{{$t('login.create')}}</router-link></h6></b-col>
                            <b-col><h6><router-link tag="a" :to="{name: 'need-help'}" class="float-right link footer-link">{{$t('login.needhelp')}}</router-link></h6></b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {Alert} from '@/components';
    import backend from "../../plugins/backend";
    import store from "../../plugins/store";

    export default {
        name: 'login',
        bodyClass: 'login-page',
        components: {
            Alert
        },
        data() {
            return {
                bgSrc: '/img/plain_black.jpg',
                logo: '/img/bet-logo-t-blue.png',
                email: '',
                pass: '',
                errors: [],
                emailRegex: /[^\.\s@][^@\s]*[^\.\s@]@[^\.\s@]+\.[^\.\s@]+/g
            }
        },
        computed: {
          loading() {
              return store.getters.isLoading;
          }
        },
        methods: {
            onDismissAlert(e) {
                this.errors = this.errors.filter(element => element !== e);
            },
            validateEmail(value) {
                if (value === '') this.errors.push('messages.email_empty');
                else {
                    if (value.startsWith('.')) this.errors.push('messages.email_invalid');
                    else if ((value.match(/@/g) || []).length !== 1) this.errors.push('messages.email_invalid');
                    else if ((value.match(this.emailRegex) || []).length !== 1) this.errors.push('messages.email_invalid');
                    else return true
                }
                return false
            },
            validatePassword(value) {
                if (value === '') {
                    this.errors.push('messages.password_empty');
                    return false
                }
                return true
            },
            onLoginBtn() {
                let email = this.email.slice();
                let pass = this.pass.slice();
                this.errors = null;
                this.errors = [];
                let validEmail = this.validateEmail(email);
                let validPassword = this.validatePassword(pass);
                if (validEmail && validPassword && this.errors.length === 0) {
                    this.onLogin(email, pass);
                }
            },
            onLogin() {
                let self = this;
                backend.signIn(this.email, this.pass).then(response => {
                    if (!response.success){
                        this.errors = response.errors;
                    } else {
                        if (response.body) {
                            let user = response.body;
                            store.commit("setCurrentUser", user);
                            let redirectTo = store.getters.redirect || 'profile';
                            store.commit('setRedirect', null);
                            self.$router.replace({name: redirectTo});
                        }
                    }
                }).catch((err)=> {
                    this.errors.push(err);
                    this.errors.push('messages.unexpected_error');
                });
            }
        },
        mounted() {
            this.alreadyLoggedInCheck('profile');
            this.errors = [];
        }
    };
</script>
<style>
</style>
