<template>
    <div class="login-page">
        <div class="page-body">
            <div v-show="loading" class="loading-spinner">
                <b-spinner class="m-auto" variant="primary" label="Spinning"></b-spinner>
            </div>
            <parallax class="page-parallax-bg" :style="'background-image: url(' + bgSrc + ');'"></parallax>
            <div class="container pt-1" v-if="errors || info">
                <alert v-for="error in errors" :key="error" class="my-1" type="danger" dismissible
                       @dismissed="onDismissAlert(error)">
                    <div class="float-left mt-1 mr-2">
                        <i class="fa fa-exclamation"></i>
                    </div>
                    <strong>{{$t('messages.error')}}!</strong> {{$t(error)}}
                </alert>
                <alert v-for="item in info" :key="item" class="m-0" type="info" dismissible
                       @dismissed="onDismissInfo(item)">
                    <div class="float-left mt-1 mr-2">
                        <i class="fa fa-exclamation"></i>
                    </div>
                    <span>{{$t(item)}}</span>
                </alert>
            </div>

            <div class="mt-0">
                <div class="container pt-0">
                    <div class="col-md-9 col-lg-6 col-xl-5 mx-auto">
                        <div class="logo-container" :style="'background-image: url(' + logo + ')'"></div>
                        <div v-if="passwordResetMode">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <span class="input-group-text"><i class="fa fa-key"></i></span>
                                </b-input-group-prepend>
                                <b-form-input type="password" v-model="pass" size="lg"
                                              :placeholder="$t('register.password')"></b-form-input>
                            </b-input-group>
                            <b-input-group>
                                <b-input-group-prepend>
                                    <span class="input-group-text"><i class="fa fa-key"></i></span>
                                </b-input-group-prepend>
                                <b-form-input type="password" v-model="passAgain" size="lg"
                                              :placeholder="$t('register.passwordAgain')"></b-form-input>
                            </b-input-group>
                            <b-button variant="primary" class="mt-5 mb-3 btn-round btn-lg w-100"
                                      v-on:click="onNewPasswordBtn">{{$t('authaction.newPasswordBtn')}}
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Alert} from '@/components';
    import store from "../../plugins/store";
    import backend from "../../plugins/backend";

    export default {
        name: "AuthAction",
        components: {
            Alert
        },
        data() {
            return {
                bgSrc: '/img/plain_black.jpg',
                logo: '/img/bet-logo-t-blue.png',
                info: [],
                errors: [],
                mode: null,
                passwordResetMode: null,
                pass: '',
                passAgain: '',
                oobCode: null
            }
        },
        computed: {
            loading() {
                return store.getters.isLoading;
            }
        },
        methods: {
            verifyEmail() {
                this.errors = [];
                this.info = [];
                if (this.oobCode)
                    backend.verifyEmail(this.oobCode).then(response => {
                        if (!response.errors) this.onEmailVerified();
                        else this.onReceivedError(response.errors);
                    }).catch(err => {
                        this.onReceivedError(err);
                    })
            },
            onEmailVerified() {
                this.info = ['E-mail cím sikeresen megerősítve! Most már bejelentkezhetsz.'];
            },
            onReceivedError(err) {
                if (err.code === 'auth/invalid-action-code') {
                    this.errors = ['messages.auth_invalid_action_code'];
                } else {
                    this.errors = [err.code];
                }
            },
            resetPasswordVerify() {
                this.errors = [];
                this.info = [];
                if (this.oobCode) {
                    backend.verifyPasswordResetRequest(this.oobCode).then(response => {
                        if (!response.errors) this.onPasswordResetRequestVerified();
                        else this.onReceivedError(response.errors);
                    }).catch(err => {
                        this.onReceivedError(err);
                    })
                }
            },
            onPasswordResetRequestVerified() {
                this.info = ['Kérlek add meg az új jelszavad!'];
                this.passwordResetMode = true;
            },
            validatePassword(value, value2) {
                if (value !== value2) {
                    this.errors.push('messages.password_doesnt_match');
                    return false
                } else if (value === '') {
                    this.errors.push('messages.password_empty');
                    return false
                } else if (value.length < 8 || value.length > 32) {
                    this.errors.push('messages.password_invalid');
                    return false;
                } else if ((value.match(this.passwordRegex) || []).length !== 1) {
                    this.errors.push('messages.password_invalid');
                    return false;
                }
                return true;
            },
            onNewPasswordBtn() {
                this.errors = [];
                this.info = [];
                let validPass = this.validatePassword(this.pass, this.passAgain);
                if (validPass && this.oobCode) {
                    backend.setNewPassword(this.oobCode, this.pass).then(response => {
                        if (!response.errors) this.onNewPasswordVerified();
                        else this.onReceivedError(response.errors);
                    }).catch(err => {
                        this.onReceivedError(err);
                    })
                }
            },
            onNewPasswordVerified() {
                this.info = ['Az új jelszó beállítva, most már bejelentkezhetsz!'];
                this.passwordResetMode = false;
            },
            changeEmail() {

            },
            init() {
                this.passwordResetMode = false;
                this.info = [];
                this.errors = [];
                if (this.mode === 'verifyEmail') this.verifyEmail();
                else if (this.mode === 'resetPassword') this.resetPasswordVerify();
                else if (this.mode === 'changeEmail') this.changeEmail();
            },
            onDismissInfo(e) {
                this.info = this.info.filter(element => element !== e);
            },
            onDismissAlert(e) {
                this.errors = this.errors.filter(element => element !== e);
            }
        },
        mounted() {
            let query = this.$route.query;
            this.mode = query.mode;
            this.oobCode = query.oobCode;
            this.init();
        }
    }
</script>

<style scoped>

</style>