<template>
    <parallax style="position: absolute;">
        <HexaTile v-for="(imageUrl, index) in actualImages" :key="index"
                  :size="size" :image="imageUrl"
                  :pos="actualPositions[index]"></HexaTile>
    </parallax>
</template>

<script>
    import HexaTile from "./HexaTile";

    export default {
        name: "HexaTiles",
        components: {
            HexaTile
        },
        props: {
            size: {
                type: Number,
                default: 8
            },
            count: {
                type: Number,
                default: 3
            },
            groupCount: {
                type: Number,
                default: 1
            },
            minLeft: {
                type: Number,
                default: -1
            },
            maxLeft: {
                type: Number,
                default: 4
            }
        },
        data() {
            return {
                imageSet: [
                    "/img/hexa-pic-1.png",
                    "/img/hexa-pic-2.png",
                    "/img/hexa-pic-3.png",
                    "/img/hexa-pic-4.png",
                    "/img/hexa-pic-5.png",
                    "/img/hexa-pic-6.png",
                    "/img/hexa-pic-7.png",
                    "/img/hexa-pic-8.png",
                    "/img/hexa-pic-9.png"
                ],
                actualImages: [],
                actualPositions: [],
                actualGroups: []
            }
        },
        computed: {},
        methods: {
            getImages(count) {
                let totalImageCount = this.imageSet.length;
                let images = [];
                while (count > totalImageCount) {
                    images.push(this.imageSet);
                    count -= totalImageCount;
                }

                let remainingImages = this.imageSet.slice();
                while (count > 0) {
                    let randomImage = remainingImages[Math.floor(Math.random() * remainingImages.length)];
                    images.push(randomImage);
                    remainingImages = remainingImages.filter(item => {
                        return item !== randomImage
                    });
                    count--;
                }

                return images.flat();
            },
            splitCountToGroups() {
                if (this.groupCount <= 1) {
                    return [this.count];
                }

                let groups = [];
                for (let g = 1; g < this.groupCount; g++) {
                    groups.push(0);
                }

                for (let i = 0; i < this.count; i++) {
                    let groupIndex = Math.floor(Math.random() * (this.groupCount - 1));
                    groups[groupIndex]++;
                }

                return groups;
            },
            getNextPosition(lastPos) {
                let plusTop1 = this.size / 2 * (1 + Math.floor(Math.random() * 2));
                let plusTop2 = this.size / 2 * (1 + Math.floor(Math.random() * 2));
                let plusTop3 = this.size / 2 * (1 + Math.floor(Math.random() * 2));
                let plusTop = Math.min(plusTop1, plusTop2, plusTop3);
                let plusLeft = 0;
                if (plusTop !== this.size) {
                    let direction = (2 * Math.floor(Math.random() * 2) - 1);
                    if (lastPos.left < (this.minLeft / 2 * this.size * 0.8)) direction = 1;
                    else if (lastPos.left > (this.maxLeft / 2 * this.size * 0.8)) direction = -1;
                    plusLeft = 0.8 * this.size * direction;
                }

                return {
                    top: lastPos.top + plusTop * 0.96,
                    left: lastPos.left + plusLeft,
                };
            },
            getPositions() {
                let positions = [];
                let groups = this.actualGroups;

                let lastPos = {
                    top: 0,
                    left: 0,
                };

                for (let g = 0; g < groups.length; g++) {
                    let currentCount = groups[g];
                    let emptyTileCount = Math.floor(Math.random() * 3);
                    for (let e = 0; e < emptyTileCount; e++) {
                        lastPos = this.getNextPosition(lastPos);
                    }

                    for (let i = 0; i < currentCount; i++) {
                        let nextPos = this.getNextPosition(lastPos);
                        positions.push(nextPos);
                        lastPos = nextPos;
                    }
                }

                return positions;
            }
        },
        mounted() {
            this.actualGroups = this.splitCountToGroups();
            this.actualGroups.forEach(group => {
                this.actualImages.push(this.getImages(group));
            });
            this.actualImages = this.actualImages.flat();
            this.actualPositions = this.getPositions();
        }
    }
</script>

<style scoped>

</style>