<template>
    <div class="introduction-page">
        <div class="overflow-hidden" :class="bodyClass">
            <parallax-page-header class="clear-filter" filter-color="introduction-cover-shadow" :src="heroSrc" :title="title" :social-media="socialMedia" :text="text"
                                  :verticalPosition="parallaxVerticalPosition" :bigger="true" :z-index="-1"/>
            <div v-show="isMobile">
                <HexaTiles style="position: absolute; top: 12rem; left: 0;z-index: -2;" :count="20" :size="9" :group-count="5"
                           :min-left="1" :max-left="5"></HexaTiles>
            </div>
            <div v-show="!isMobile">
                <HexaTiles style="position: absolute; top: 12rem; left: 0;z-index: -2;" :count="10" :size="14" :group-count="4"
                           :min-left="0" :max-left="1"></HexaTiles>
                <HexaTiles style="position: absolute; top: 12rem; right: 0;z-index: -2;" :count="10" :size="14" :group-count="4"
                           :min-left="-3" :max-left="-1"></HexaTiles>
            </div>
            <parallax class="page-parallax-bg"></parallax>
            <div class="clear-filter" filter-color="introduction-page-shadow" >
                <div class="page-style-2" :class="paragraphClass">
                    <div :class="paragraphColClass">
                        <h2 class="title text-center">Kik vagyunk?</h2>
                        <h5 class="text-justify">
                            Csapatunk több mint <b>10 éves</b> sportfogadói <b>tapasztalattal</b> és széleskörű <b>kapcsolatrendszerrel</b>
                            rendelkezik szerte a világon, aminek köszönhetően olyan tippeket és információkat tudunk elétek
                            tárni, ami a sportfogadás világában utat mutat a <b>biztos profit</b> felé. Sportolói múltunknak
                            köszönhetően olyan embereket ismerhettünk meg, akik ma is szerves tagjai az aktív sportolói
                            életnek
                            kis és topligákban egyaránt. Ezeknek a kapcsolatainknak köszönhetően olyan valós és megbízható
                            információkat tudunk elétek tárni, ami által a sportfogadásban rejlő szerencsefaktort a lehető
                            legminimálisabbra redukálva növeljük a nyerési esélyeinket.
                        </h5>
                    </div>
                </div>
                <div class="page-style-2" :class="paragraphClass">
                    <div :class="paragraphColClass">
                        <div class="text-justify h5">
                            Szeretnénk a sportfogadásnak egy olyan oldalát megmutatni, ami hosszútávon kifizetődő és egy <b>fix
                            havi fizetés kiegészítést</b> jelent. Ez azonban a <b>szabályok</b> szigorú betartásával jár,
                            amelynek alapkövei:
                            <ul class="mt-2 ml-4">
                                <li>az érzelmek teljes kizárása</li>
                                <li>a tétek megfelelő nagyságának használata</li>
                                <li>az önkontroll gyakorlása</li>
                            </ul>
                            Tudni kell nemet mondani a fogadóirodák által kínált napi több ezer olyan eseménynek, melyek
                            kimenetele szinte megjósolhatatlan és azokat a mérkőzéseket megjátszani, amiben valós értékek
                            rejlenek.
                        </div>
                    </div>
                </div>
                <div class="page-style-2" :class="paragraphClass">
                    <div :class="paragraphColClass">
                        <h5>
                            A fogadásban rejlő legnagyobb értéket az <b>információ hatalma</b> képviseli.
                            Elég csak belegondolnunk, hogy egy kiélezett mérkőzésen egy-egy kulcsjátékos
                            hiánya, a csapat rossz egészségügyi állapota vagy a klub csőd közeli anyagi
                            helyzete mennyire befolyásolhatja az adott esemény kimenetelét. A megfelelő és
                            <b>megbízható forrásból</b> szerzett információ segít, hogy te is azon sportfogadók
                            szűk százalékba tartozz, akik ezt a hobbit/”szakmát” <b>nyereségesen</b> űzik. Ám ez
                            önmagában nem elég!
                        </h5>
                        <h5>
                            A sikeres sportfogadás alapja a hosszú távú gondolkodás. Ahhoz, hogy valóban nyereségesen
                            tudjunk
                            zárni egy hetet, hónapot, elengedhetetlen, hogy tőkénket megfelelően használjuk, de ne aggódj
                            ebben is
                            segítségedre leszünk!
                        </h5>
                        <h5>
                            Célunk nem csak az, hogy akárkiből tudatos sportfogadót faragjunk, hanem egy olyan fogadói és
                            szurkolói <b>közösséget</b> építsünk ki, ami Magyarországon egyedülálló. Segítsük egymást a
                            sportfogadás
                            rögös útján és töltsük meg a lelátókat a sport szerelmeseivel!
                        </h5>
                        <h4>
                            <b>Hajrá Magyarok!</b>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ParallaxPageHeader from "../components/ParallaxPageHeader";
    import HexaTiles from "../components/HexaTiles";

    export default {
        name: 'introduction',
        components: {
            ParallaxPageHeader,
            HexaTiles
        },
        computed: {
            windowWidth() {
                return this.$root.windowWidth;
            },
            isMobile() {
                return this.$root.isMobile;
            },
            paragraphClass() {
                if (this.isMobile) return ['m-page-paragraph'];
                else return ['page-paragraph'];
            },
            bodyClass() {
                if (this.isMobile) return ['m-page-body'];
                else return ['page-body'];
            },
            paragraphColClass() {
                return ['col-12', 'col-md-11', 'col-lg-10', 'col-xl-9', 'm-auto'];
            }
        },
        data() {
            return {
                heroSrc: '/img/hero-introduction.jpg',
                title: '',
                text: '',
                socialMedia: false,
                parallaxVerticalPosition: 'top'
            };
        }
    };
</script>

<style scoped>
</style>