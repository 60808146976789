import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

const messages = {
        hu: {
            siteName: 'Sportfogadók Clubja',
            language: 'Nyelv',
            languages: {
                hu: 'Magyar',
                en: 'Angol'
            },
            common: {
                edit: 'Szerkesztés',
                create: 'Létrehozás',
                delete: 'Törlés',
                view: 'Megtekintés'
            },
            form: {
                firstName: 'Utónév',
                lastName: 'Vezetéknév',
                cityVillage: 'Város/falu',
                addressLine: 'Címsor',
                postCode: 'Irányítószám',
                phone: 'Telefon',
                email: 'E-mail',
                coupon: 'Kuponkód'
            },
            receipt: {
                status: {
                    completed: 'Teljesítve',
                    ordered: 'Megrendelve',
                    megrendelve: 'Megrendelve',
                    declined: 'Elutasítva',
                    feldolgozva: 'Feldolgozva'
                }
            },
            pages: {
                index: 'Főoldal',
                login: 'Bejelentkezés',
                signUp: 'Regisztráció',
                profile: 'Profil',
                introduction: 'Rólunk',
                statistics: 'Statisztikák',
                bundles: 'Csomagok',
                tips: 'Tippek',
                comingSoon: 'Hamarosan',
                admin: 'Admin',
                logout: 'Kijelentkezés',
                tutorial: 'Stratégiai útmutató'
            },
            navBar: {
                menu: 'Menü',
                siteNamePopover: ''
            },
            introduction: {
                title: 'Rólunk',
                subtitle: ''
            },
            statistics: {
                title: 'Statisztika',
                subtitle: ''
            },
            bundles: {
                title: 'Csomagok',
                subtitle: ''
            },
            tips: {
                title: 'Fogadási tippek',
                subtitle: 'A tippek csak bejelentkezés után érhetőek el. A regisztráció ingyenes.',
                free: 'Ingyenes',
                tippmix: 'Tippmix',
                online: 'Online nagytétes',
                premium: 'Prémium',
                highstake: 'Nagytétes',
                basic: 'Prematch & Élő',
                tip_idea: 'tipp ötlet',
                match_status: {
                    ongoing: 'Még nincs eredmény',
                    won: 'Nyert',
                    lost: 'Vesztett',
                    return: 'Tét visszajár'
                }
            },
            login: {
                email: 'E-mail cím',
                password: 'Jelszó',
                loginbtn: 'Bejelentkezés',
                create: 'Új felhasználó',
                needhelp: 'Elfelejtett jelszó?'
            },
            register: {
                name: 'Név',
                email: 'E-mail cím',
                password: 'Jelszó',
                passwordAgain: 'Jelszó megerősítése',
                registerbtn: 'Regisztráció',
                login: 'Bejelentkezés',
                needhelp: 'Elfelejtett jelszó?'
            },
            needhelp: {
                email: 'E-mail cím',
                resetbtn: 'Jelszó visszaállítása'
            },
            footer: {
                createdBy: 'Készítette:'
            },
            authaction: {
                newPasswordBtn: 'Jelszó beállítása'
            },
            messages: {
                error: 'Hiba',
                unexpected_error: 'Váratlan hiba történt! Ha továbbra is fennáll, kérlek vedd fel velünk a kapcsolatot, hogy kijavíthassuk!',
                name_length_error: 'Kérlek adj meg egy nevet 2-64 karakter között!',
                email_invalid: 'Hibás e-mail cím formátum!',
                password_invalid: 'Hibás jelszó forma! Legyen 8-32 karakter hosszú, tartalmazzon legalább egy nagy és kisbetűt, valamint egy számot!',
                invalid_request: 'Váratlan hiba történt!',
                email_empty: 'Az e-mail cím mező üres!',
                password_empty: 'A jelszó mező üres!',
                password_doesnt_match: 'A két jelszó nem egyezik!',
                user_not_found: 'Felhasználó nem található ilyen e-mail címmel!',
                wrong_password: 'Hibás jelszó!',
                email_already_in_use: 'Ezzel az e-mail címmel már regisztráltak!',
                register_success: 'Sikeres regisztráció! Kérlek erősítsd meg az e-mail címed belépés előtt!',
                user_not_verified: 'Kérlek erősítsd meg az e-mail címed belépés előtt!',
                auth_invalid_action_code: 'A link hibás, elavult vagy már fel lett használva!',
                aszf_error: 'Az Általános Szerződési Feltételeket és Adatvédelmi Szabályzatot el kell fogadni a regisztrációhoz!'
            },
            profile: {
                user: 'Felhasználó',
                bundles: 'Összes csomag',
                tips: 'Tovább a tippekhez',
                edit: 'Profil szerkesztése',
                admin: 'Admin panel'
            },
            userRole: {
                admin: 'Admin',
                premium: 'Prémium',
                normal: 'Ingyenes'
            },
            bundle: {
                premium: {
                    title: 'Prémium csomag'
                },
                basketball: {
                    title: 'Kosár csomag'
                },
                mustang: {
                    title: 'Mustang csomag'
                },
                start: {
                    title: 'Start csomag'
                },
                silver: {
                    title: 'Silver csomag'
                },
                gold: {
                    title: 'Gold csomag'
                },
                combo: {
                    title: 'Kombinált csomag'
                }
            },
            mail: {
                title: 'E-mail szolgáltatás',
                news: 'Hírlevél',
                promotion: 'Promóciók/Kedvezmények',
                personal: 'Személyes'
            }
        },
        en: {
            siteName: 'Sportfogadók Clubja',
            language: 'Language',
            languages: {
                hu: 'Hungarian',
                en: 'English'
            },
            pages: {
                index: 'Home',
                login: 'Log in',
                signUp: 'Sign up',
                profile: 'Profile',
                introduction: 'Introduction',
                statistics: 'Statistics',
                bundles: 'Bundles',
                tips: 'Tips',
                comingSoon: 'Coming soon',
                logout: 'Log out',
                tutorial: 'Tutorial'
            },
            navBar: {
                menu: 'Menu',
                siteNamePopover: ''
            },
            common: {
                edit: 'Edit',
                create: 'Create',
                delete: 'Delete',
                view: 'View'
            },
            form: {
                firstName: 'First name',
                lastName: 'Last name',
                cityVillage: 'City/Village',
                addressLine: 'Address line',
                postCode: 'Post code',
                phone: 'Phone',
                email: 'E-mail',
                coupon: 'Coupon code'
            },
            receipt: {
                status: {
                    completed: 'Completed',
                    ordered: 'Ordered',
                    megrendelve: 'Ordered',
                    declined: 'Declined',
                    feldolgozva: 'Processed'
                }
            },
            introduction: {
                title: 'Introduction',
                subtitle: ''
            },
            statistics: {
                title: 'Statistics',
                subtitle: 'Graphs about our experience'
            },
            bundles: {
                title: 'Bundles',
                subtitle: 'Earn together'
            },
            tips: {
                title: 'Betting tips',
                subtitle: 'Tips are available after login. Register free now!',
                free: 'Free',
                tippmix: 'Tippmix',
                online: 'Online high stake',
                highstake: 'High stake',
                basic: 'Prematch & Live',
                premium: 'Premium',
                tip_idea: 'tip idea',
                match_status: {
                    ongoing: 'Ongoing',
                    won: 'Won',
                    lost: 'Lost',
                    return: 'Return'
                }
            },
            login: {
                email: 'E-mail address',
                password: 'Password',
                loginbtn: 'Login',
                create: 'Create account',
                needhelp: 'Forgot password?'
            },
            register: {
                name: 'Name',
                email: 'E-mail address',
                password: 'Password',
                passwordAgain: 'Confirm password',
                registerbtn: 'Create account',
                login: 'Log in',
                needhelp: 'Forgot password?'
            },
            needhelp: {
                email: 'E-mail address',
                resetbtn: 'Reset password'
            },
            authaction: {
                newPasswordBtn: 'Set password'
            },
            footer: {
                createdBy: 'Created by'
            },
            messages: {
                error: 'Error',
                unexpected_error: 'Unexpected error! If the error lasts, please contact us, so we can fix the problem!',
                name_length_error: 'Please enter a name with 2-64 characters!',
                email_invalid: 'Invalid e-mail address format!',
                password_invalid: 'Invalid password! 8-32 characters, must contain at least one uppercase letter, one lowercase letter and one number!',
                invalid_request: 'Invalid request!',
                email_empty: 'Empty e-mail address!',
                password_empty: 'Empty password!',
                password_doesnt_match: 'The passwords doesn\'t match!',
                user_not_found: 'User not found with this e-mail!',
                wrong_password: 'Incorrect password!',
                email_already_in_use: 'This e-mail is already registered!',
                register_success: 'Successful registration! Please verify your e-mail address!',
                user_not_verified: 'Please verify your e-mail address!',
                auth_invalid_action_code: 'The link is invalid, expired or had been used already!'
            },
            profile: {
                user: 'User',
                bundles: 'All bundle',
                tips: 'Continue to tips',
                edit: 'Edit profile',
                admin: 'Admin panel'
            },
            userRole: {
                admin: 'Admin',
                premium: 'Premium',
                normal: 'Free'
            },
            bundle: {
                premium: {
                    title: 'Premium bundle'
                },
                basketball: {
                    title: 'Basketball bundle'
                },
                mustang: {
                    title: 'Mustang bundle'
                },
                start: {
                    title: 'Start bundle'
                },
                silver: {
                    title: 'Silver bundle'
                },
                gold: {
                    title: 'Gold bundle'
                },
                combo: {
                    title: 'Combo bundle'
                }
            },
            mail: {
                title: 'E-mail service',
                news: 'News',
                promotion: 'Promotion/Discount',
                personal: 'Personal'
            }
        }
    }
;

export const i18n = new VueI18n({
    locale: 'hu',
    messages,
});
