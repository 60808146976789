<template>
    <b-row class="my-tab-content" align-h="center"
           :class="{'ml-2': !isMobile}">
        <b-col class="overflow-auto ml-5">
            <b-table :items="configs" :fields="fields">
                <template v-slot:cell(type)="data">
                    <b-row align-v="center">
                        <div class="font-weight-bold ml-3" style="line-height: 3em;">
                            {{$t('bundle.' + data.item.type + '.title')}}
                        </div>
                    </b-row>
                </template>
                <template v-slot:cell(currentValue)="data">
                    <b-row align-v="center" align-h="center">
                        <b-button pill variant="danger"
                                  @click="updateBundleConfig(data.item.type, data.item.currentValue - 1, data.item.limit)">
                            -
                        </b-button>
                        <div class="font-weight-bold mx-2">
                            {{data.item.currentValue}}
                        </div>
                        <b-button pill variant="success"
                                  @click="updateBundleConfig(data.item.type, data.item.currentValue + 1, data.item.limit)">
                            +
                        </b-button>
                    </b-row>
                </template>
                <template v-slot:cell(limit)="data">
                    <b-row align-v="center" align-h="center">
                        <div style="line-height: 3em;">
                            {{data.item.limit}}
                        </div>
                    </b-row>
                </template>
            </b-table>
        </b-col>
    </b-row>
</template>

<script>
import backend from "../../plugins/backend";
import store from "../../plugins/store";

export default {
    name: "BundleConfigs",
    data() {
        return {
            configs: [],
            fields: [
                {
                    key: 'type',
                    label: 'Típus',
                    thClass: 'h3 font-weight-bold',
                    tdClass: 'h4'
                },
                {
                    key: 'currentValue',
                    label: 'Aktuális létszám',
                    class: 'text-center',
                    thClass: 'h3 font-weight-bold',
                    tdClass: 'h4'
                },
                {
                    key: 'limit',
                    label: 'Max létszám',
                    thClass: 'h3 text-center font-weight-bold',
                    tdClass: 'h4 font-weight-bold'
                }
            ]
        }
    },
    computed: {
        isMobile() {
            return this.$root.isMobile;
        }
    },
    methods: {
        loadBundleConfigs() {
            let self = this;
            backend.getBundleConfigs().then(response => {
                self.configs = response.body;
            }).catch(err => {
            })
        },
        updateBundleConfig(type, newValue, limit) {
            let self = this;
            if (newValue > limit || newValue < 0) return;
            backend.putBundleConfig(type, newValue, limit).then(response => {
                self.loadBundleConfigs();
            }).catch(err => {
                self.loadBundleConfigs();
            })
        }
    },
    mounted() {
        this.loadBundleConfigs();
    }
}
</script>

<style scoped>
</style>