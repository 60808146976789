<template>
    <b-row class="my-tab-content" align-h="center"
           :class="{'ml-2': !isMobile}">
        <b-col class="overflow-auto p-0">
            <b-pagination align="fill"
                          v-model="currentPage"
                          :total-rows="users.length"
                          :per-page="perPage"
                          aria-controls="users-table"
            >
            </b-pagination>
            <b-button class="btn-lg" @click="downloadUsers" variant="primary">
                Összes személy letöltése
            </b-button>
            <b-form-group label="Keresés" class="text-white" label-class="h4 font-weight-bold mb-0 ml-1 w-75">
                <b-form-input class="text-white bg-secondary h5" type="text" v-model="filter"></b-form-input>
            </b-form-group>
            <b-table id="users-table" class="users-table" dark :items="users" :fields="fields"
                     :per-page="perPage" :filter="filter" :current-page="currentPage">
                <template v-slot:cell(actions)="data">
                    <b-button variant="primary" @click="data.toggleDetails()">
                        {{ data.detailsShowing ? '&#x25B2;' : '&#x25BC;' }} Részletek
                    </b-button>
                    <router-link tag="b-button" class="btn-primary border-0 outline-0"
                                 :to="{name: 'admin-user-page', params: {userId: data.item.userId}}">
                        {{ $t('common.edit') }}
                    </router-link>
                </template>
                <template v-slot:row-details="data" class="outline-0">
                    <b-card class="m-0 outline-0" style="background-color: #00000044">
                        <b-row class="outline-0" align-h="center">
                            <b-col cols="12" md="10" xl="8" class="outline-0">
                                <InvoiceForm class="outline-0" :form="data.item.invoiceData || {}"
                                             :editable="false"></InvoiceForm>
                            </b-col>
                        </b-row>
                    </b-card>
                </template>
            </b-table>
            <b-pagination align="fill"
                          v-model="currentPage"
                          :total-rows="users.length"
                          :per-page="perPage"
                          aria-controls="users-table"
            >
            </b-pagination>
        </b-col>
    </b-row>
</template>

<script>
import backend from "../../plugins/backend";
import InvoiceForm from "../profile/InvoiceForm";

export default {
    name: "Users",
    data() {
        return {
            currentPage: 1,
            perPage: 25,
            filter: '',
            users: [],
            fields: []
        }
    },
    components: {
        InvoiceForm
    },
    computed: {
        isMobile() {
            return this.$root.isMobile;
        }
    },
    methods: {
        loadUsers() {
            let self = this;
            backend.getAllUser().then(response => {
                self.users = response.body;
                self.users.forEach(user => {
                    let str = '';
                    user.activeBundles.forEach(item => {
                        str += self.$t('bundle.' + item.id).title
                    });
                    user.activeBundles = str ? str : 'Ingyenes';
                });
            }).catch(err => {
            });
        },
        initFields() {
            let self = this;
            this.fields = [
                {
                    key: 'name',
                    label: 'Név',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true
                },
                {
                    key: 'userRole',
                    label: 'Jogkör',
                    sortable: true
                },
                {
                    key: 'activeBundles',
                    label: 'Csomagok',
                    sortable: true,
                    sortByFormatted: true
                },
                {
                    key: 'creationTime',
                    label: 'Regisztrált',
                    sortable: true,
                    formatter(value) {
                        return self.printDate((new Date(value)).getTime() / 1000);
                    }
                },
                {
                    key: 'lastSignIn',
                    label: 'Utolsó belépés',
                    sortable: true,
                    formatter(value) {
                        return self.printDate((new Date(value)).getTime() / 1000);
                    }
                },
                {
                    key: 'verified',
                    label: 'Email megerősítve',
                    sortable: true,
                    formatter(value) {
                        return value === true ? 'Igen' : 'Nem'
                    }
                },
                {
                    key: 'actions',
                    label: 'Műveletek',
                    tdClass: 'p-0'
                }
            ]
        },
        downloadUsers() {
            let self = this;
            if (self.users.length > 0) {
                let columns = [
                    "Név", "E-mail", "Jogkör", "Aktív csomagok", "Regisztráció dátuma", "Utoljára belépett", "Engedélyezett", "E-mail cím megerősítve"
                ];
                let title = "Felhasználók";
                let subject = "";
                let data = self.users.map(item => {
                    return {
                        'name': item.name ? item.name : 'N/A',
                        'email': item.email ? item.email : 'N/A',
                        'userRole': item.userRole ? item.userRole : 'N/A',
                        'activeBundles': item.activeBundles ? item.activeBundles : 'N/A',
                        'creationTime': item.creationTime ? self.printDate((new Date(item.creationTime)).getTime() / 1000) : 'N/A',
                        'lastSignIn': item.lastSignIn ? self.printDate((new Date(item.lastSignIn)).getTime() / 1000) : 'N/A',
                        'enabled': item.enabled ? item.enabled : false,
                        'verified': item.verified ? item.verified : false
                    }
                })
                let xlsx = self.createXLSX(title, subject, columns, data)
                self.saveFile(title, xlsx);
            }
        }
    },
    mounted() {
        this.initFields();
        this.loadUsers();
    }
}
</script>

<style scoped>
.users-table {
    min-width: 600px;
}
</style>