<template>
    <b-row class="my-tab-content" align-h="center"
           :class="{'ml-2': !isMobile}">
        <b-col class="overflow-auto p-0">
            <b-pagination align="fill"
                          v-model="currentPage"
                          :total-rows="receipts.length"
                          :per-page="perPage"
                          aria-controls="receipts-table"
            >
            </b-pagination>
            <b-form-group label="Keresés" class="text-white" label-class="h4 font-weight-bold mb-0 ml-1 w-75">
                <b-form-input class="text-white bg-secondary h5" type="text" v-model="filter"></b-form-input>
            </b-form-group>
            <b-table id="receipts-table" class="receipts-table" dark :items="receipts" :fields="fields"
                     :per-page="perPage" :filter="filter" :current-page="currentPage">
                <template v-slot:cell(status)="data">
                    <b-dropdown :text="data.item.status.text || ''" variant="primary">
                        <b-dropdown-item  class="h5" v-for="status in availableStatuses" :key="status.id"
                                          @click="setReceiptStatus(data.item, status)">
                            {{status.text}}
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
                <template v-slot:cell(actions)="data">
                    <router-link tag="b-button" class="btn-primary border-0 outline-0" :to="{name: 'admin-user-page', params: {userId: data.item.userId}}">
                        Ugrás a személyhez
                    </router-link>
                    <b-button variant="primary" @click="data.toggleDetails()">
                        {{ data.detailsShowing ? '&#x25B2;' : '&#x25BC;' }} Részletek
                    </b-button>
                </template>
                <template v-slot:row-details="data" class="outline-0">
                    <b-card class="m-0 outline-0" style="background-color: #00000044">
                        <b-row align-h="center">
                            <b-col cols="12" md="10" xl="8">
                                <b-table dark :items="data.item.products" :fields="productFields"></b-table>
                                <hr style="border-color: grey">
                            </b-col>
                        </b-row>
                        <b-row class="outline-0" align-h="center">
                            <b-col cols="12" md="10" xl="8" class="outline-0">
                                <InvoiceForm class="outline-0" :form="data.item.form || {}" :editable="false"></InvoiceForm>
                            </b-col>
                        </b-row>
                    </b-card>
                </template>
            </b-table>
            <b-pagination align="fill"
                          v-model="currentPage"
                          :total-rows="receipts.length"
                          :per-page="perPage"
                          aria-controls="receipts-table"
            >
            </b-pagination>
        </b-col>
    </b-row>
</template>

<script>
    import backend from "../../plugins/backend";
    import InvoiceForm from "../profile/InvoiceForm";
    import store from "../../plugins/store";

    export default {
        name: "Receipts",
        data() {
            return {
                currentPage: 1,
                perPage: 25,
                filter: '',
                receipts: [],
                fields: [],
                productFields: [],
                availableStatuses: [
                    {
                        id: 'ORDERED',
                        text: this.$t('receipt.status.ordered')
                    },
                    {
                        id: 'COMPLETED',
                        text: this.$t('receipt.status.completed')
                    },
                    {
                        id: 'DECLINED',
                        text: this.$t('receipt.status.declined')
                    }
                ]
            }
        },
        computed: {
            isMobile() {
                return this.$root.isMobile;
            }
        },
        components: {
            InvoiceForm
        },
        methods: {
            setReceiptStatus(item, status) {
                this.$set(item, 'status', status);
                backend.updateReceiptStatus(item.orderId, status.id).then(response => {
                }).catch(err => {
                })
            },
            loadReceipts() {
                let self = this;
                backend.getAllReceipt().then(response => {
                    self.receipts = response.body;
                    self.receipts.forEach(receipt => {
                        receipt.status = {
                            id: receipt.status,
                            text: self.$t('receipt.status.' + receipt.status.toLowerCase())
                        };
                    })
                }).catch(err => {
                });
            },
            initFields() {
                let self = this;
                self.fields = [
                    {
                        key: 'createTime',
                        label: 'Időpont',
                        sortable: true,
                        formatter: (value) => {
                            return self.printDate(Math.floor(new Date(value).getTime() / 1000));
                        }
                    },
                    {
                        key: 'orderId',
                        label: 'Azonosító',
                        sortable: true
                    },
                    {
                        key: 'userName',
                        label: 'Név',
                        sortable: true
                    },
                    {
                        key: 'status',
                        label: 'Státusz',
                        sortable: true
                    },
                    {
                        key: 'method',
                        label: 'Fizetési mód',
                        sortable: true
                    },
                    {
                        key: 'valid',
                        label: 'Érvényes',
                        sortable: true,
                        formatter: (value) => {
                            return value ? 'IGEN' : 'NEM'
                        }
                    },
                    {
                        key: 'actions',
                        label: 'Műveletek',
                        tdClass: 'p-0'
                    }
                ];
                self.productFields = [
                    {
                        key: 'description',
                        label: 'Tétel'
                    },
                    {
                        key: 'price',
                        label: 'Ár',
                        formatter: (value) => {
                            return self.formatNumberWithUnit(value, 'Ft')
                        }
                    }
                ]
            }
        },
        mounted() {
            this.initFields();
            this.loadReceipts();
        }
    }
</script>

<style scoped>
</style>