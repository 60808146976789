<template>
    <navbar position="fixed"
            type="primary"
            menu-classes="ml-auto text-golden text-navbar"
            :color-on-scroll="getColorOnScroll">
        <template>
            <router-link v-popover:popover1 class="navbar-brand p-0 text-navbar" to="/">
                <img class="mr-2 text-navbar my-1" style="height: 58px; margin-bottom: 0.125rem;" src="/img/bet-logo-circled.png"/><div class="nav-site-name">{{$t('siteName')}}</div>
            </router-link>
        </template>
        <template slot="navbar-menu">
            <drop-down-hover v-if="dropdownNeeded" class="nav-item" tag="li" :title="$t('navBar.menu')">
                <router-link v-for="item in dropdownMenu" :key="item.to" tag="li" class="dropdown-item nav-link"
                             :to="{name: item.to}">
                    {{$t(item.title)}}
                </router-link>
            </drop-down-hover>
            <li v-else class="nav-item" v-for="item in dropdownMenu" :key="item.to">
                <router-link class="nav-link" :to="{name: item.to}">
                    {{$t(item.title)}}
                </router-link>
            </li>
            <li v-if="isLoggedIn" class="nav-item">
                <a href="https://firebasestorage.googleapis.com/v0/b/sportfogadokclubja.appspot.com/o/public%2Fdoc%2FBankroll-menedzsment_utmutato.pdf?alt=media" target="_blank" class="nav-link">
                    {{$t('pages.tutorial')}}
                </a>
            </li>
            <li v-if="!isLoggedIn" class="nav-item">
                <router-link class="nav-link" :to="{name: 'login'}">
                    {{$t('pages.login')}}
                </router-link>
            </li>
            <li v-if="!isLoggedIn" class="nav-item">
                <router-link class="nav-link" :to="{name: 'register'}">
                    {{$t('pages.signUp')}}
                </router-link>
            </li>
            <li v-if="isLoggedIn" class="nav-item">
                <router-link class="nav-link" :to="{name: 'profile'}">
                    {{$t('pages.profile')}}
                </router-link>
            </li>
            <li v-if="isLoggedIn && userRole.toUpperCase() === 'ADMIN'" class="nav-item">
                <router-link class="nav-link" :to="{name: 'admin-panel'}">
                    {{$t('pages.admin')}}
                </router-link>
            </li>
            <drop-down-hover v-if="false" tag="li" :title="$t('language')">
                <li @click="onSelectLanguage('hu')" class="dropdown-item nav-link">
                    <div>
                        <i style="vertical-align: super;"><img src="/img/flags/HU.png"/></i>
                        <p>{{$t('languages.hu')}}</p>
                    </div>
                </li>
                <li @click="onSelectLanguage('en')" class="dropdown-item nav-link">
                    <div>
                        <i style="vertical-align: super;"><img src="/img/flags/GB.png"/></i>
                        <p>{{$t('languages.en')}}</p>
                    </div>
                </li>
            </drop-down-hover>
            <li v-if="isLoggedIn" class="nav-item">
                <div class="nav-link" @click="logout">
                    {{$t('pages.logout')}}
                </div>
            </li>
        </template>
    </navbar>
</template>

<script>
    import {DropDownHover, Navbar} from '@/components';
    import {Popover} from 'element-ui';
    import store from "../plugins/store";
    import backend from "../plugins/backend";

    export default {
        name: 'sc-navbar',
        data() {
            return {
                dropdownNeeded: false,
                dropdownMenu: []
            }
        },
        props: {
            colorOnScroll: {
                type: Number,
                default: 0
            }
        },
        computed: {
            isLoggedIn() {
                return store.getters.userAccessToken !== 'guest';
            },
            isMobile() {
                return this.$root.isMobile;
            },
            getColorOnScroll() {
                if (this.isMobile) return 0;
                return this.colorOnScroll;
            },
            userRole() {
                if (!store.getters.user) return  'guest';
                return store.getters.user.userRole;
            }
        },
        components: {
            DropDownHover,
            Navbar,
            [Popover.name]: Popover
        },
        methods: {
            onSelectLanguage(lang) {
                this.$i18n.locale = lang
            },
            initDropdownMenu() {
                this.dropdownMenu = [
                    {
                        to: 'introduction',
                        title: 'pages.introduction'
                    },
                    {
                        to: 'statistics',
                        title: 'pages.statistics'
                    },
                    {
                        to: 'bundles',
                        title: 'pages.bundles'
                    }
                ];
                if (this.dropdownNeeded) {
                    this.dropdownMenu.unshift({
                        to: 'index',
                        title: 'pages.index'
                    })
                }
            },
            reloadMenus() {
                this.initDropdownMenu();
            },
            logout() {
                let self = this;
                backend.signOut().then(() => {
                    store.commit("setCurrentUser", null);
                    self.$router.replace({name: "index"});
                }).catch(() => {
                    store.commit("setCurrentUser", null);
                    self.$router.replace({name: "index"});
                });
            }
        },
        mounted() {
            this.reloadMenus();
        }
    };
</script>

<style>
    .text-navbar .nav-link {
        font-size: 1.1em !important;
    }

    .nav-site-name {
        font-size: 1.2em;
        vertical-align: middle;
        display: inline-block;
    }

    .nav-item {
        cursor: pointer;
    }

    .dropdown-item {
        cursor: pointer;
    }

    ul.dropdown-menu {
        overflow: hidden !important;
        height: auto !important;
    }

</style>
