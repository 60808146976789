import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
    key: 'vuex',
    storage: localStorage,
});

let initialState = {
    currentUser: null,
    loading: false,
    redirect: null,
    cart: {}
};

export default new Vuex.Store({
    state: initialState,
    mutations: {
        setCurrentUser(state, payload) {
            state.currentUser = payload;
        },
        setLoading(state, payload) {
            state.loading = payload;
        },
        setRedirect(state, payload) {
            state.redirect = payload;
        },
        reset(state) {
            state.currentUser = null;
            state.scUser = null;
            state.loading = false;
            state.cart = {};
        },
        addToCart(state, payload) {
            state.cart = payload;
        },
        resetCart(state) {
            state.cart = {};
        }
    },
    getters: {
        user: (state) => state.currentUser,
        isLoading: (state) => state.loading,
        redirect: (state) => state.redirect,
        userAccessToken: (state) => {
            if (state.currentUser) {
                return state.currentUser.uid
            } else {
                return 'guest'
            }
        },
        getCart: (state) => state.cart
    },
    plugins: [vuexLocalStorage.plugin],
})
