import axios from 'axios'
import store from './store'

const client = axios.create({
    baseURL: 'https://back.sportfogadokclubja.hu:3443/api',
    json: true
});

client.interceptors.request.use(
    conf => {
        store.commit("setLoading", true);
        return conf;
    },
    error => {
        store.commit("setLoading", false);

        return Promise.reject(error);
    }
);

client.interceptors.response.use(
    response => {
        store.commit("setLoading", false);
        return response;
    },
    error => {
        store.commit("setLoading", false);
        return Promise.reject(error);
    }
);

export default {
    async execute(method, resource, data) {
        let accessToken = await store.getters.userAccessToken;

        return client({
            method,
            url: resource,
            data,
            headers: {
                'Accept': 'application/json',
                'Authorization': `SCAuth ${accessToken}`
            }
        }).then(req => {
            return req.data
        })
    },
    signIn(email, password) {
        let body = {
            'email': email,
            'password': password
        };
        return this.execute('post', '/user/signinwithemailandpassword', body);
    },
    registerWithEmailPassword(email, password, name) {
        let body = {
            'email': email,
            'password': password,
            'name': name
        };
        return this.execute('post', '/user/registerwithemailandpassword', body);
    },
    checkLogin() {
        return this.execute('get', '/user/checkloginstatus');
    },
    signOut() {
        return this.execute('get', '/user/signout');
    },
    verifyEmail(oobCode) {
        return this.execute('get', '/user/confirmemail?oobCode=' + oobCode)
    },
    requestPasswordReset(email) {
        let data = {
            email: email
        };
        return this.execute('post', 'user/requestpasswordreset', data)
    },
    verifyPasswordResetRequest(oobCode) {
        return this.execute('get', '/user/verifypasswordresetrequest?oobCode=' + oobCode)
    },
    setNewPassword(oobCode, password) {
        let data = {
            password: password
        };
        return this.execute('post', '/user/setnewpassword?oobCode=' + oobCode, data)
    },
    getBundles() {
        return this.execute('get', '/bundle/getall')
    },
    getBundleById(id) {
        return this.execute('get', '/bundle/get/' + id)
    },
    getProduct(type, id, option, coupon) {
        let params = '?type=' + type + '&option=' + option;
        if (coupon) {
            params += '&coupon=' + coupon
        }
        return this.execute('get', '/product/get/' + id + params)
    },
    getProductOptions(type, id) {
        let params = '?type=' + type;
        return this.execute('get', '/product/options/' + id + params)
    },
    getActiveBundles() {
        return this.execute('get', '/user/activebundles')
    },
    registerBundleByPayPal(order, coupon) {
        let params = '';
        if (coupon) {
            params = '?coupon=' + coupon
        }
        return this.execute('post', '/bundle/registerbundlebypaypal' + params, order)
    },
    requestOrder(form, product, coupon) {
        let params = '';
        if (coupon) {
            params = '?coupon=' + coupon;
        }
        let body = {
            form: form,
            product: product
        };
        return this.execute('post', '/bundle/requestorder' + params, body)
    },
    getStatBetsByType(start, end, type) {
        let param = type || 'all';
        return this.execute('get', '/bet/getallstat/' + param + '?start=' + start + '&end=' + end)
    },
    getBetsByType(type) {
        let param = type || 'all';
        return this.execute('get', '/bet/getall/' + param)
    },
    getBetById(id) {
        return this.execute('get', '/bet/get/' + id)
    },
    getPublicBetById(id) {
        return this.execute('get', '/bet/get/public/' + id)
    },
    updateBet(bet) {
        let body = {
            bet: bet
        };
        return this.execute('post', '/bet/update', body)
    },
    deleteBet(betId) {
        return this.execute('post', '/bet/delete/' + betId)
    },
    postBet(bet) {
        let body = {
            bet: bet
        };
        return this.execute('post', '/bet/create', body)
    },
    getStatisticByType(type, interval = 'weekly') {
        let param = type || 'all';
        return this.execute('get', '/statistic/get/' + param)
    },
    postStatistic(type, year, month, profit) {
        let body = {
            year: year,
            month: month,
            profit: profit
        }
        return this.execute('post', '/statistic/post/' + type, body);
    },
    getFreshBetsForUser() {
        return this.execute('get', '/bet/getfreshbets/')
    },
    getAllUser() {
        return this.execute('get', '/user/getall')
    },
    getUserById(id) {
        return this.execute('get', '/user/get/' + id)
    },
    updateUser(userId, user) {
        let body = {
            user: user
        };
        return this.execute('post', '/user/update/' + userId, body);
    },
    getAllReceipt() {
        return this.execute('get', '/receipt/getall')
    },
    getReceiptsConnectedToUser(userId) {
        return this.execute('get', '/receipt/getbyuser/' + userId)
    },
    getReceiptsForUser() {
        return this.execute('get', '/receipt/getforuser')
    },
    updateReceiptStatus(orderId, status) {
        return this.execute('post', '/receipt/update/' + orderId + '?status=' + status);
    },
    getDiscounts() {
        return this.execute('get', '/discounts/get')
    },
    getBundleConfigs() {
        return this.execute('get', '/config/bundle/all')
    },
    putBundleConfig(type, value, limit) {
        let query = '?type=' + type + '&value=' + value + '&limit=' + limit
        return this.execute('put', '/config/bundle' + query);
    },
    sendMail(mail) {
        return this.execute('post', '/mail/send', mail)
    },
    getMailOptions() {
        return this.execute('get', '/user/mailoptions')
    },
    setMailOptions(mailOptions) {
        return this.execute('post', '/user/mailoptions', mailOptions)
    }
}