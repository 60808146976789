<template>
  <div class="introduction-page">
    <div class="overflow-hidden">
      <parallax class="page-parallax-bg"></parallax>
      <div style="width: 100%;" class="clear-filter" filter-color="introduction-page-shadow">
        <div class="container clear-filter" filter-color="introduction-page-shadow-text">
          <iframe style="border: 0; margin-top: 80px; min-height: calc(100vh - 155px)" width="100%" :src="src"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PDF",
  props: {
    src: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>