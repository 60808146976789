var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header",class:[{'page-header-small': _vm.isMobile || _vm.small}, {'page-header-bigger': !_vm.isMobile && !_vm.small && _vm.bigger}, {'page-header-extra-big': !_vm.isMobile && _vm.extraBig}]},[_c('parallax',{staticClass:"page-header-image",style:([
          {'background-image': 'url(' + _vm.src + ')'},
          {'background-position': 'center ' + _vm.verticalPosition},
          {'z-index': _vm.zIndex}
      ])}),_c('div',{staticClass:"content-center"},[_c('div',{staticClass:"container"},[(_vm.title)?_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(_vm.title)))]):_vm._e(),(_vm.buttons !== null && _vm.buttons.length > 0)?_c('b-row',{attrs:{"align-h":"center"}},_vm._l((_vm.buttons),function(button){return _c('router-link',{key:button.text,class:_vm.buttonClass(button.variant, button.color),attrs:{"tag":"button","to":{name: button.link}}},[_vm._v(" "+_vm._s(button.text)+" ")])}),1):_vm._e(),(_vm.socialMedia)?_c('div',{staticClass:"text-center"},[_vm._m(0),_vm._m(1),_vm._m(2)]):_vm._e(),(_vm.text)?_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t(_vm.text)))]):_vm._e()],1)]),(!_vm.isMobile && _vm.extraBig)?_c('div',{staticClass:"content-bottom"},[(_vm.paragraphs !== null && _vm.paragraphs.length > 0)?_c('b-row',{staticClass:"w-100",attrs:{"align-h":"center"}},_vm._l((_vm.paragraphs),function(p,index){return _c('b-col',{key:p[0],attrs:{"cols":"4","xl":"3"}},[_c('b-row',{staticClass:"m-0 p-0"},[_c('div',{staticClass:"m-0 mr-2 p-0 text-golden text-right",staticStyle:{"font-size":"4rem","line-height":"1"}},[_vm._v(_vm._s(index + 1))]),_c('b-col',{staticClass:"m-0 p-0 text-justify"},[_c('b-row',{staticClass:"m-0"},[_c('div',{staticClass:"text-golden h3 m-0"},[_vm._v(_vm._s(p[0]))])]),_c('b-row',{staticClass:"m-0"},[_c('div',{staticClass:"h5"},[_vm._v(_vm._s(p[1]))])])],1)],1)],1)}),1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn btn-primary btn-lg btn-icon btn-round",attrs:{"href":"https://www.facebook.com/SportfogadokClubja","target":"_blank"}},[_c('i',{staticClass:"fab fa-facebook"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn btn-primary btn-lg btn-icon btn-round",attrs:{"href":"https://www.instagram.com/sportfogadokclubja","target":"_blank"}},[_c('i',{staticClass:"fab fa-instagram"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn btn-primary btn-lg btn-icon btn-round",attrs:{"href":"https://t.me/SportfogadokClubja","target":"_blank"}},[_c('i',{staticClass:"fab fa-telegram"})])
}]

export { render, staticRenderFns }