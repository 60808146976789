<template>
    <div class="tips-body">
        <div v-show="loading" class="loading-spinner">
            <b-spinner class="m-auto" variant="primary" label="Spinning"></b-spinner>
        </div>
        <parallax-page-header :src="heroSrc" :title="title" :social-media="socialMedia" :text="text"
                              :vertical-position="parallaxVerticalPosition"/>
        <parallax class="page-parallax-bg"></parallax>
        <b-row class="items-container mx-auto" style="position: relative;">
            <div class="background-hack clear-filter" filter-color="tips-page-shadow"></div>
            <b-col class="px-0 mx-0">
                <b-row align-h="center">
                    <b-col style="cursor: pointer" v-if="saveSuccess" cols="8" class="text-center"
                           @click="saveSuccess = false">
                        <alert class="h5 my-1" type="info">
                            <strong>Sikeres mentés</strong>
                        </alert>
                    </b-col>
                    <b-col style="cursor: pointer" v-if="saveFailed" cols="8" class="text-center"
                           @click="saveFailed = false">
                        <alert class="h5 my-1" type="danger">
                            <strong>Sikertelen mentés</strong>
                        </alert>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <bet-row :bet="bet" :template="true"/>
                    </b-col>
                </b-row>
                <b-row align-h="center">
                    <b-col cols="12" md="10">
                        <b-card>
                            <b-row>
                                <b-col cols="12">
                                    <div class="h3 font-weight-bold text-primary">Szerkesztő felület</div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <div class="h4 mt-0 font-weight-bold text-primary">Típus kiválasztás</div>
                                </b-col>
                                <b-col v-for="betType in availableBetTypes" :key="betType" cols="4">
                                    <b-button class="w-100 h6"
                                              :variant="betType === bet.betType ? 'primary' : 'secondary'"
                                              style="height: 100px; font-size: 1.2em;" @click="setBetType(betType)"
                                              :style="{'color': betType === bet.betType ? 'white' : 'black'}">
                                        {{$t('tips.' + betType)}}
                                    </b-button>
                                </b-col>
                            </b-row>
                            <hr style="border-color: black; border-width: 2px;">
                            <b-row>
                                <b-col cols="12">
                                    <div class="h4 mt-0 font-weight-bold text-primary">Kép kiválasztása</div>
                                </b-col>
                                <b-col class="my-2 px-2" v-for="img in availableImages" :key="img" cols="6" sm="4"
                                       md="4" lg="3" xl="2">
                                    <div class="bet-logo" :class="{'bet-logo-active': img === bet.img}"
                                         :style="'background-image: url(' + '/img/' + img + ');'"
                                         @click="onBetImgClick(img)"></div>
                                </b-col>
                                <b-col cols="12">
                                    <b-row class="h5">
                                        <b-col cols="12">
                                            <b-form-checkbox v-model="customImageCheckbox"
                                                             @change="customImageCheckboxChecked">Egyedi kép URL
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col v-show="customImageCheckbox" cols="12" md="8">
                                            <b-form-input style="border-color: black" type="text"
                                                          v-model="bet.img"></b-form-input>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <hr style="border-color: black; border-width: 2px;">
                            <b-row>
                                <b-col cols="12">
                                    <div class="h4 mt-0 font-weight-bold text-primary">Tét megadása</div>
                                </b-col>
                                <b-col cols="12">
                                    <b-row class="h5 font-weight-bold">
                                        <b-col cols="4" md="3" lg="3" xl="2">Tét:</b-col>
                                        <b-col cols="8" md="7" lg="5" xl="5">
                                            <b-form-input style="border-color: black" type="number" min="0.5" step="0.5"
                                                          v-model="bet.totalAmount"></b-form-input>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <hr style="border-color: black; border-width: 2px;">
                            <b-row>
                                <b-col cols="12">
                                    <div class="h4 mt-0 font-weight-bold text-primary">Mérkőzések</div>
                                </b-col>
                                <b-col class="h5 font-weight-bold" v-for="(match, index) in bet.matches" :key="index"
                                       cols="12">
                                    <b-row class="my-1">
                                        <b-col cols="8" md="9">{{'#' + (1 + index) + ' Mérkőzés'}}</b-col>
                                        <b-col cols="4" md="3">
                                            <b-button class="w-100 h6" variant="danger" @click="deleteMatch(index)">
                                                <i class="fa fa-minus-circle mr-2"></i> Mérkőzés törlése
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                    <b-row class="my-1">
                                        <b-col cols="3" sm="4" md="3" lg="3" xl="2">Időpont:</b-col>
                                        <b-col cols="5" sm="4" lg="3" xl="3">
                                            <b-form-input style="border-color: black" type="date" v-model="match.date"
                                                          @change="onDateTimeChange(match)"></b-form-input>
                                        </b-col>
                                        <b-col cols="4" md="3" lg="2" xl="2">
                                            <b-form-input style="border-color: black" type="time" v-model="match.time"
                                                          @change="onDateTimeChange(match)"></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <b-row class="my-1">
                                        <b-col cols="4" md="3" lg="3" xl="2">Bajnokság:</b-col>
                                        <b-col cols="8" md="7" lg="5" xl="5">
                                            <b-form-input style="border-color: black" type="text"
                                                          v-model="match.cup"></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <b-row class="my-1">
                                        <b-col cols="4" md="3" lg="3" xl="2">Hazai csapat:</b-col>
                                        <b-col cols="8" md="7" lg="5" xl="5">
                                            <b-form-input style="border-color: black" type="text"
                                                          v-model="match.home"></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <b-row class="my-1">
                                        <b-col cols="4" md="3" lg="3" xl="2">Vendég csapat:</b-col>
                                        <b-col cols="8" md="7" lg="5" xl="5">
                                            <b-form-input style="border-color: black" type="text"
                                                          v-model="match.guest"></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <b-row class="my-1">
                                        <b-col cols="4" md="3" lg="3" xl="2">Fogadási opció:</b-col>
                                        <b-col cols="8" md="7" lg="5" xl="5">
                                            <b-form-input style="border-color: black" type="text"
                                                          v-model="match['option']"></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                        <b-col cols="4" md="3" lg="3" xl="2">Odds:</b-col>
                                        <b-col cols="8" md="7" lg="5" xl="5">
                                            <b-form-input style="border-color: black" type="number" min="1" step="0.01"
                                                          v-model="match.odds" @change="onOddsChange"></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row class="my-1" align-v="center">
                                        <b-col cols="4" md="3" lg="3" xl="2">Kimenetel:</b-col>
                                        <b-col cols="8" md="7" lg="5" xl="5">
                                            <b-dropdown :text="match.status || 'Még nincs eredmény'" variant="primary">
                                                <b-dropdown-item  class="h5" v-for="status in availableMatchStatuses" :key="status"
                                                                 @click="setMatchStatus(match, status)">
                                                    {{status}}
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                        <b-col cols="4" md="3" lg="3" xl="2">Eredmény:</b-col>
                                        <b-col cols="8" md="7" lg="5" xl="5">
                                            <b-form-input style="border-color: black" type="text"
                                                          v-model="match.result"></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <hr>
                                </b-col>
                                <b-col class="px-2" cols="12">
                                    <b-button class="h5" variant="primary" @click="addNewEmptyMatch"><i
                                            class="fa fa-plus-circle mr-2"></i>Mérkőzés hozzáadása
                                    </b-button>
                                </b-col>
                            </b-row>
                            <hr style="border-color: black; border-width: 2px;">
                            <b-row>
                                <b-col cols="12">
                                    <div class="h4 mt-0 font-weight-bold text-primary">Leírás</div>
                                </b-col>
                                <b-col class="h5 font-weight-bold" v-for="(paragraph, index) in bet.paragraphs"
                                       :key="index" cols="12"
                                       style="background-color: rgba(25,37,55,0.05);">
                                    <b-row class="my-1">
                                        <b-col cols="8" md="9">{{'#' + (1 + index) + ' Bekezdés'}}</b-col>
                                        <b-col cols="4" md="3">
                                            <b-button class="w-100 h6" variant="danger" @click="deleteParagraph(index)">
                                                <i class="fa fa-minus-circle mr-2"></i> Bekezdés törlése
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <b-form-textarea style="border: 1px solid black;" rows="6"
                                                             class="new-tip-textarea"
                                                             v-model="bet.paragraphs[index]"></b-form-textarea>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col class="px-2" cols="12">
                                    <b-button class="h5" variant="primary" @click="addNewEmptyParagraph"><i
                                            class="fa fa-plus-circle mr-2"></i>Bekezdés hozzáadása
                                    </b-button>
                                </b-col>
                            </b-row>
                            <hr style="border-color: black; border-width: 2px;">
                            <b-row align-h="center">
                                <b-col cols="4" class="text-center">
                                    <b-button class="w-100 h5 font-weight-bold" variant="danger" @click="onDeleteBtn">
                                        Törlés
                                    </b-button>
                                </b-col>
                                <b-col cols="4" class="text-center">
                                    <b-button class="w-100 h5 font-weight-bold" variant="success" @click="onSubmitBtn">
                                        Mentés
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-row align-h="center">
                                <b-col style="cursor: pointer" v-if="saveSuccess" cols="8" class="text-center"
                                       @click="saveSuccess = false">
                                    <alert class="h5 my-1" type="info">
                                        <strong>Sikeres mentés</strong>
                                    </alert>
                                </b-col>
                                <b-col style="cursor: pointer" v-if="saveFailed" cols="8" class="text-center"
                                       @click="saveFailed = false">
                                    <alert class="h5 my-1" type="danger">
                                        <strong>Sikertelen mentés</strong>
                                    </alert>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-modal id="modal-delete-confirm" centered size="lg" title="Biztosan törlöd a tippet?" title-class="h2 mb-0"
                 cancel-title="Mégsem" ok-title="Törlés" ok-variant="danger" cancel-variant="primary"
                 button-size="lg" @ok="deleteBet" @cancel="cancelDelete"
        >
        </b-modal>
    </div>
</template>

<script>
    import {Alert} from '@/components';
    import BetRow from "../tips/BetRow";
    import ParallaxPageHeader from "../components/ParallaxPageHeader";
    import store from "../../plugins/store";
    import backend from "../../plugins/backend";

    export default {
        name: "BetPage",
        components: {
            ParallaxPageHeader,
            BetRow,
            Alert
        },
        computed: {
            loading() {
                return store.getters.isLoading;
            },
            self(){
                return this;
            },
            currentUser() {
                return store.getters.user;
            }
        },
        props: {
            betId: {
                type: String
            }
        },
        data() {
            return {
                heroSrc: '/img/hero-tips.jpg',
                title: 'tips.title',
                text: 'common.edit',
                socialMedia: false,
                parallaxVerticalPosition: '80%',
                bet: {},
                availableImages: [
                    'adidas_heaven.jpg',
                    'bundle_big.jpg',
                    'hero-money.jpg',
                    'hero-vegas.jpg',
                    'horse-racing-1.jpg',
                    'horse-racing-2.jpg',
                    'onlinebundle.jpg',
                    'tippmix.jpg',
                    'wave_four.jpg',
                    'mff_mez.jpg',
                    'bayerische_water.jpg',
                    'greenland.jpg'
                ],
                availableBetTypes: [
                    'free',
                    'highstake',
                    'basic'
                ],
                availableMatchStatuses: [
                    'Még nincs eredmény',
                    'Nyert',
                    'Vesztett',
                    'Tét visszajár'
                ],
                timer: '',
                saveSuccess: false,
                saveFailed: false,
                customImageCheckbox: false,
                customImageUrl: ''
            }
        },
        methods: {
            setBetType(betType) {
                this.$set(this.bet, 'betType', betType);
            },
            onBetImgClick(img) {
                if (this.customImageCheckbox) {
                    this.customImageUrl = this.bet.img;
                    this.customImageCheckbox = false;
                }

                this.setBetImg(img);
            },
            setBetImg(img) {
                this.$set(this.bet, 'img', img);
            },
            setMatchStatus(match, status) {
                this.$set(match, 'status', status);
                if (match.status) {
                    if (match.status === 'Nyert') this.$set(match, '_rowVariant', 'success');
                    else if (match.status === 'Vesztett') this.$set(match, '_rowVariant', 'danger');
                    else if (match.status === 'Tét visszajár') this.$set(match, '_rowVariant', 'info');
                    else match._rowVariant = 'default';
                }
                this.updateBetStatus();
            },
            updateBetStatus(){
                let status = '';
                let profit = 0;

                let statusProfit = this.calculateProfit(this.bet.matches, this.bet.totalAmount, this.bet.totalOdds);
                if (statusProfit.length === 2) {
                    status = statusProfit[0];
                    profit = statusProfit[1];
                }

                this.$set(this.bet, 'status', status);
                this.$set(this.bet, 'profit', profit);
            },
            addNewEmptyMatch() {
                let emptyMatch = {
                    targetDate: '',
                    date: this.printDateYYYYMMDD(new Date().getTime() / 1000),
                    time: this.printTimeHHMM(new Date().getTime() / 1000),
                    cup: '',
                    home: '',
                    guest: '',
                    option: '',
                    odds: '',
                    status: '',
                    result: ''
                };
                this.$set(this.bet.matches, this.bet.matches.length, emptyMatch);
                this.onDateTimeChange(emptyMatch);
                this.updateBetStatus();
            },
            onDateTimeChange(match) {
                match.targetDate = new Date(match.date + 'T' + match.time).getTime() / 1000;
                this.updateBetTargetDate();
            },
            onOddsChange() {
                let result = 1.0;
                if (this.bet.matches.length > 0) {
                    this.bet.matches.forEach(match => {
                        result = result * match.odds;
                    });
                }
                this.$set(this.bet, 'totalOdds', result);
                this.updateBetStatus();
            },
            updateBetTargetDate() {
                if (this.bet.matches.length > 0) {
                    let minDate = this.bet.matches.map(x => {
                        return x.targetDate
                    }).sort()[0];
                    this.$set(this.bet, 'targetDate', minDate);
                }
            },
            addNewEmptyParagraph() {
                if (!this.bet.paragraphs){
                    this.$set(this.bet, 'paragraphs', ['']);
                } else {
                    this.$set(this.bet.paragraphs, this.bet.paragraphs.length, '');
                }
            },
            deleteParagraph(index) {
                if (index > -1) {
                    this.bet.paragraphs.splice(index, 1);
                }
            },
            deleteMatch(index) {
                if (index > -1) {
                    this.bet.matches.splice(index, 1);
                    this.onOddsChange();
                }
            },
            checkLogin() {
                let self = this;
                let onReject = () => {
                    store.commit('setRedirect', 'admin-panel')
                };
                let onResolve = () => {
                    if (self.currentUser.userRole.toLowerCase() !== 'admin') {
                        self.$router.replace({name: 'profile'});
                    }
                };
                this.loginCheck('login', null, onResolve, onReject)
            },
            startLoginCheckTimer() {
                if (this.timer) clearInterval(this.timer);
                this.timer = setInterval(this.checkLogin, 60000)
            },
            stopLoginCheckTimer() {
                if (this.timer) {
                    clearInterval(this.timer);
                }
            },
            customImageCheckboxChecked(checked) {
                if (checked) {
                    this.setBetImg(this.customImageUrl);
                } else {
                    this.customImageUrl = this.bet.img;
                    this.setBetImg('bundle_big.jpg');
                }
            },
            initBetModel() {
                let self = this;
                if (self.betId && self.betId !== 'new') {
                    backend.getBetById(self.betId).then(response => {
                        self.bet = response.body;
                        self.bet.id = self.betId;
                        if (!self.bet.img) {
                            self.setBetImg('bundle_big.jpg');
                        } else {
                            if (self.bet.img.startsWith('http')) {
                                self.customImageUrl = self.bet.img;
                                self.customImageCheckbox = true;
                            }
                        }

                        self.bet.matches.forEach(match => {
                            match.date = self.printDateYYYYMMDD(match.targetDate);
                            match.time = self.printTimeHHMM(match.targetDate);
                        });
                    }).catch(err => {
                    })
                } else {
                    if (self.betId === 'new') {
                        self.initDefaultEmptyBet();
                    }
                }
            },
            initDefaultEmptyBet() {
                let self = this;
                self.text = 'common.create';
                self.bet = {
                    targetDate: (new Date()).getTime() / 1000,
                    totalAmount: 1,
                    totalOdds: 1.0,
                    betType: 'free',
                    img: 'hero-money.jpg',
                    matches: [],
                    paragraphs: []
                };
                self.setBetImg('hero-money.jpg');
            },
            onSubmitBtn() {
                this.saveSuccess = false;
                this.saveFailed = false;
                if (this.betId === 'new') {
                    backend.postBet(this.bet).then(response => {
                        this.saveSuccess = true;
                        let newId = response.body._id;
                        if (newId) {
                            this.$router.replace({name: 'admin-bet-page', params: {'betId': newId}});
                        } else {
                            this.initDefaultEmptyBet();
                        }
                    }).catch(err => {
                        this.saveFailed = true;
                    });
                } else {
                    backend.updateBet(this.bet).then(response => {
                        this.saveSuccess = true;
                    }).catch(err => {
                        this.saveFailed = true;
                    });
                }
            },
            onDeleteBtn() {
                if (this.betId === 'new') {
                    this.initDefaultEmptyBet();
                } else {
                    this.$root.$emit('bv::show::modal', 'modal-delete-confirm');
                }
            },
            deleteBet() {
                let self = this;
                self.saveSuccess = false;
                self.saveFailed = false;
                if (self.betId && self.betId !== 'new') {
                    backend.deleteBet(self.betId).then(response => {
                        if (response.success) {
                            self.saveSuccess = true;
                            setTimeout(()=> {
                                self.$router.replace({name: 'admin-bet-page', params: {'betId': 'new'}});
                                self.initDefaultEmptyBet();
                            }, 200);
                        }
                    }).catch(err => {
                        self.saveFailed = true;
                    })
                }
            },
            cancelDelete() {
                this.toDeleteBet = null;
            }
        },
        mounted() {
            this.checkLogin();
            this.startLoginCheckTimer();
            this.initBetModel();
        },
        beforeDestroy() {
            this.stopLoginCheckTimer();
        },
        watch: {
            betId() {
                if (this.betId === 'new') this.text = 'common.create';
                else this.text = 'common.edit';
            }
        }
    }
</script>

<style scoped>
    .bet-logo:hover {
        cursor: pointer;
    }

    .bet-logo-active {
        border: red 0.25rem solid;
    }
</style>